import styled from 'styled-components'
import { bp, mq, rem, theme } from 'styles/Utilities.styled';

export const StyledError = styled.section`
    padding-top: 7rem;

    ${mq(bp.small)} {
        padding-top: 9rem;
    }

    .error-img-wrapper {
        width: min(100%, ${rem(500)});
        margin: 0 auto;

        svg {
            max-width: 100%;
        }
    }

    .text-content {
        margin-top: 2rem;
        text-align: center;
    }

    .heading {
        font-size: clamp(3.5rem, 5vw, 6rem);
        color: ${theme.colors.primary700};
    }

    .sub-heading {
        margin-top: .5rem;
        color: ${theme.colors.primary700};
        font-size: 1.2rem;
        font-weight: 500;
        margin-top: .5rem;
    }

    .summary {
        margin-top: .5rem;
        font-weight: 300;
    }

    .cta {
        display: block;
        width: fit-content;
        margin: 2rem auto 0;
    }
`;