import styled from "styled-components";
import { alpha, bp, flexCenter, flexColumn, mq, rem, size, theme } from "styles/Utilities.styled";

export const StyledCircularCard = styled.div`
    position: relative;
    /* outline: 1px solid red; */

    ${mq(bp.small)} {
        ${size(rem(320))};
        ${flexCenter};

        &:hover,
        &:focus {

            .icon-wrapper {
                transform: scale(2.5);
            }

            .icon {
                font-size: 1.5rem;
                transform: translateY(270%);
            }

            .text-banner {
                transform: translateY(-10%);
                opacity: 0;
            }

            .tc-subheading,
            .tc-heading {
                opacity: 1;
                transform: translate(-50%, 0);
            }

            .tc-subheading {
                transition: opacity .3s .5s, transform .3s .5s;
            }
            
            .tc-heading {
                transition: opacity .3s .3s, transform .3s .3s;
            }

            .text {
                z-index: 1;
                opacity: 1;
                transform: translateY(0);
                transition: opacity .3s .3s, transform .3s .3s;
            }
        }

        /* &:focus:not(:hover) {
            outline: unset; // removes the ugly outline for browser default
        } */
    }

    .icon-wrapper {
        display: grid;
        place-items: center;
        ${size(rem(80))};
        margin-top: 1.5rem;
        border-radius: 50%;
        background-color: ${({light_color, override_light_color_alpha}) => override_light_color_alpha ? light_color : alpha(light_color, .25) };
        transition: transform .5s ${theme.ease.almostLinear};
        
        ${mq(bp.small)} {
            ${size(rem(130))};
            margin-top: 0;
        }
    }

    .icon {
        font-size: 2.5rem;
        transition: font-size .3s, transform .3s ${theme.ease.almostLinear};
        color: ${({deep_color}) => deep_color};
    }

    .tc-subheading,
    .tc-heading {
        font-weight: 500;

        ${mq(bp.small)} {
            opacity: 0;
            position: absolute;
            top: 15%;
            left: 50%;
            transform: translate(-50%, -20%);
            margin-top: 0;
            width: 80%;
            color: ${theme.colors.primary700};
            font-weight: 600;
            text-align: center;
        }
    }

    .tc-subheading {
        font-size: ${rem(14)};
        color: ${({deep_color}) => deep_color};

        ${mq(bp.small)} {
            font-size: ${rem(15)};
        }
    }
    
    .tc-heading {
        font-size: ${rem(23)};
        margin-top: .7rem;
        line-height: 1em;

        ${mq(bp.small)} {
            margin-top:1.5rem;
            font-size: ${rem(20)};
            font-weight: 700;
        }
    }

    .text {
        margin-top: 1rem;
        
        ${mq(bp.small)} {
            position: absolute;
            top: 33%;
            padding-inline: 1em;
            text-align: center;
            z-index: -1;
            opacity: 0;
            transform: translateY(10%);
        }
    }
    
    .text-banner {
        display: none;
        
        ${mq(bp.small)} {
            ${flexColumn};
            position: absolute;
            bottom: 0;
            text-align: center;
            width: 100%;
            opacity: 1;
            transition: transform .3s, opacity .5s;
        }
    }
    
    .tb-subheading {
        font-weight: 500;
        font-size: ${rem(14.5)};
        color: ${theme.colors.primaryDesat300};
    }
    
    .tb-heading {
        color: ${({deep_color}) => deep_color};
        font-weight: 600;
        font-size: ${rem(27)};
    }
`;