import { StyledHero } from "./Hero.styled";
import { Container } from "styles/components/Container.styled";
import { SectionSummary } from "styles/components/SectionText.styled";

export const Hero = () => {

  return (
    <StyledHero>
      <div aria-hidden="true" className="decor">
        <div className="background-container">
          <div className="grid">
            <div className="background"></div>
          </div>
        </div>
        <div className="stripes-container">
          <div className="grid-1">
            <div className="stripe"></div>
            <div className="stripe"></div>
            <div className="stripe"></div>
            <div className="stripe"></div>
            <div className="stripe"></div>
            <div className="stripe"></div>
            <div className="stripe"></div>
            <div className="stripe"></div>
            <div className="stripe"></div>
            <div className="stripe"></div>
          </div>
          <div className="grid-2">
            <div className="stripe"></div>
            <div className="stripe"></div>
          </div>
        </div>
      </div>
      <Container>
        <div className="text-content">
          <h1 className="heading">Our mission is to create digital solutions for your business needs</h1>
          <SectionSummary className="summary">
            Polymorph Labs is a software agency that makes outstanding things to the delight of our clients and the world.
            But our focus is on helping our clients tackle the most delicate business problems and come up with the most ingenious digital solutions that easily bests competition.
          </SectionSummary>
        </div>
      </Container>
    </StyledHero>
  );
};
