import styled from "styled-components";
import { theme, rem, size, flexCenter, mq, bp, alpha, pseudo, flexColumn } from "styles/Utilities.styled";

export const StyledFooter = styled.footer`
    position: relative;
    margin-top: ${({ is_contact_us_page }) => is_contact_us_page ? '7rem' : '14rem'};
    padding: 0 0 1rem;
    padding-top: ${({ is_contact_us_page }) => is_contact_us_page ? '4rem' : '11rem'};
    font-size: ${rem(14.5)};
    font-weight: 300;
    color: ${theme.colors.primary100};
    background-color: ${theme.colors.primary600};
    
    ${mq(bp.small)} {
        padding-top: ${({ is_contact_us_page }) => is_contact_us_page ? '4rem' : '9rem'};
    }

    .footer-wrapper {
        ${mq(bp.small)} {
            display: flex;
            flex-flow: row wrap;
            column-gap: 3rem;
            row-gap: 2rem;
        }

        ${mq(bp.medium)} {
            row-gap: 2rem;
            justify-content: space-between;
        }

        ${mq(bp.large)} {
            gap: 0;
            justify-content: space-between;
        }
    }
    
    .footer-about {
        /* outline: 1px solid red; */

        &-logo {
            display: inline-block;
            font-size: clamp(1.6rem, 4vw, 1.8rem);
            font-weight: 700;
            color: ${theme.colors.white}; 
        }

        &-text {
            margin-top: 1rem;
            max-width: 45ch;
            color: ${theme.colors.white};

            span {
                display: block;
            }
        }
    }

    .social-icons {
        display: flex;
        justify-content: space-between;
        width: min(62%, ${rem(180)});
        margin: 1.5rem 0;
        /* outline: 1px solid; */

        li {
            ${flexCenter};
            ${size(rem(38))};
            border-radius: 50%;
            background: none;
            border: 1px solid ${alpha(theme.colors.primary100, .3)};
            transition: background .3s, border-color .3s;
            cursor: pointer;

            .icon {
                color: ${theme.colors.primary100};
            }

            &:hover {
                border-color: transparent;
                .icon {
                    color: ${theme.colors.white};
                }
            }

            &:nth-child(1):hover {
                background: ${theme.colors.facebook}
            }
            &:nth-child(2):hover {
                background: ${theme.colors.instagram}
            }
            &:nth-child(3):hover {
                background: ${theme.colors.twitter}
            }
            &:nth-child(4):hover {
                background: ${theme.colors.linkedin}
            }
            
            a {
                ${size(rem(17))};

                .icon {
                    ${size('100%')}
                    color: ${theme.colors.gray100};
                    transition: color .3s;
                }
            }
        }
    }

    .nav-links {
        /* outline: 1px solid; */
        
        li {
            width: fit-content;
            
            a {
                position: relative;
                color: ${theme.colors.primary100};
                white-space: nowrap;
                letter-spacing: .03em;
                z-index: 0;
                transition: color .3s;

                &:hover {
                    color: ${theme.colors.white};
    
                    &::before,
                    &::after {
                        transform: skewY(20deg) rotate(-19deg) scaleX(1);
                    }
                    &::after {
                        transition: transform .2s .3s;
                    }
                }

                &::before,
                &::after {
                    ${pseudo};
                    height: 50%;
                    transform: skewY(20deg) rotate(-19deg) scaleX(0);
                    transform-origin: left;
                    z-index: -1;
                }
                
                &::before {
                    top: 50%;
                    left: -3px;
                    width: 45%;
                    background-color: ${alpha(theme.colors.accent400, .8)};
                    transition: transform .4s;
                }
                &::after {
                    bottom: -30%;
                    left: 20%;
                    width: 25%;
                    background-color: ${alpha(theme.colors.accent3, .8)};
                }
            }
        }

        li + li {
            margin-top: .5rem;
        }
    }

    .footer-heading {
        margin-bottom: 1rem;
        color: ${theme.colors.white};
        font-weight: 600;
        font-size: 1rem;
    }

    .footer-heading:first-child {
        margin-top: 2.5rem;

        ${mq(bp.small)} {
            margin-top: 0;
        }
    }

    .footer-contact {

        &-item {
            display: flex;
            gap: .7rem;

            a {
                color: ${theme.colors.primary100};
            }

            .icon {
                color: ${theme.colors.white};
                font-size: 1.1rem;
                margin-top: .2rem;
            }
        }

        .footer-contact-item + .footer-contact-item {
            margin-top: .5rem;
        }

        .wrapper {
            ${flexColumn};
            gap: .2rem;
        }
    }

    .copyright {
        margin-top: 3rem;
        font-size: ${rem(14)};
        color: ${theme.colors.primaryDesat300};
        text-align: center;

        ${mq(bp.small)} {
            display: flex;
            gap: .5rem;
            width: fit-content;
            margin-inline: auto;
        }

        span {
            color: ${theme.colors.white};
        }
    }
`;