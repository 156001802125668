import { BsArrowRight } from "react-icons/bs";

import {
  StyledLinkButton,
  StyledLinkAButton,
  StyledLinkTAButton,
} from "./LinkButton.styled";

// Link Button - could be primary/secondary
export const LinkButton = ({ text, ...rest }) => {
  return <StyledLinkButton {...rest}>{text}</StyledLinkButton>;
};

// Link Arrow Button - could be primary/secondary
export const LinkAButton = ({ text, ...rest }) => {
  return (
    <StyledLinkAButton {...rest}>
      <span>{text}</span>
      {<BsArrowRight />}
    </StyledLinkAButton>
  );
};

// Tertiary Link Arrow Button
export const LinkTAButton = ({ text, ...rest }) => {
  return (
    <StyledLinkTAButton {...rest}>
      <span>{text}</span>
      {<BsArrowRight />}
    </StyledLinkTAButton>
  );
};
