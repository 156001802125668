import { useEffect, useRef, useState } from "react";

import { Link, useLocation } from "react-router-dom";
import { gsap } from "gsap";

import { Container } from "styles/components/Container.styled";
import { StyledHeader } from "./Header.styled";
import { theme, bp } from "styles/Utilities.styled";
import { useIsHomePage } from "hooks/useIsHomepage";
import { isMatchMediaMinWidth } from "utils/matchMedia";

export const Header = ({ isMenuOpen, setIsMenuOpen }) => {
  const [isCloseAnimationPlaying, setIsCloseAnimationPlaying] = useState(false);
  const menuRef = useRef(null);
  const menuLayerRef = useRef(null);
  const logoRef = useRef(null);
  const toggleBtnRef = useRef(null);
  const wrapperRef = useRef(null);
  const openAnimationTL = useRef(null);
  const closeAnimationTL = useRef(null);
  const isHomePage = useIsHomePage();
  const location = useLocation();
  const body = document.getElementById("body");

  const openAnimationSetup = () => {
    openAnimationTL.current = gsap.timeline({ paused: true });
    openAnimationTL.current
      .fromTo(
        menuLayerRef.current,
        { x: "100%" },
        { x: 0, duration: 0.3, onStart: () => setIsCloseAnimationPlaying(true) }
      )
      .fromTo(
        menuRef.current,
        { x: "100%", opacity: 1 },
        { x: 0, duration: 0.6, opacity: 1 },
        ""
      )
      .to(
        [toggleBtnRef.current.children[0]],
        {
          y: 0,
          rotation: 45,
          background: theme.colors.primary700,
          duration: 0.2,
        },
        ""
      )
      .to(
        [toggleBtnRef.current.children[1]],
        { opacity: 0, background: theme.colors.primary700, duration: 0.2 },
        ""
      )
      .to(
        [toggleBtnRef.current.children[2]],
        {
          y: 0,
          rotation: -45,
          background: theme.colors.primary700,
          duration: 0.2,
        },
        ""
      )
      .to(
        logoRef.current,
        { duration: 0.3, color: theme.colors.primary700 },
        ""
      )
      .fromTo(
        [menuRef.current.children],
        { x: 20, opacity: 0 },
        {
          x: 0,
          opacity: 1,
          duration: 0.3,
          stagger: 0.1,
          // onComplete: () => console.log("finished open animation"),
        },
        "+=.1"
      );
  };

  const checkForCloseAnimationPlaying = () => {
    if (isCloseAnimationPlaying) {
      return;
    } else {
      if (isHomePage) {
        // console.log('no animation playing and homepage')
        return theme.colors.white;
      } else {
        // console.log('no animation playing and NOT homepage')
        return theme.colors.primary700;
      }
    }
  };

  let rightLogoColor = checkForCloseAnimationPlaying();

  useEffect(() => {
    openAnimationSetup(); // to prevent close animation from playing upon initial render of page
  }, []);

  useEffect(() => {
    rightLogoColor = checkForCloseAnimationPlaying();
  }, [location]);

  useEffect(() => {
    if (!isMatchMediaMinWidth(bp.medium)) {
      if (isMenuOpen) {
        openAnimationSetup();
        openAnimationTL.current.timeScale(1);
        openAnimationTL.current.play();
        body.style.overflow = "hidden";
      } else {
        body.style.overflow = "auto";
        closeAnimationTL.current = gsap.timeline({ paused: true });
        closeAnimationTL.current
          .to([menuRef.current.children], {
            x: 20,
            opacity: 0,
            duration: 0.3,
            stagger: -0.1,
          })
          .to(
            [toggleBtnRef.current.children[0]],
            {
              y: -9,
              rotation: 0,
              background: isHomePage
                ? theme.colors.white
                : theme.colors.primary700,
              duration: 0.4,
            },
            ""
          )
          .to(
            [toggleBtnRef.current.children[1]],
            {
              opacity: 1,
              background: isHomePage
                ? theme.colors.white
                : theme.colors.primary700,
              duration: 0.4,
            },
            ""
          )
          .to(
            [toggleBtnRef.current.children[2]],
            {
              y: 9,
              rotation: 0,
              background: isHomePage
                ? theme.colors.white
                : theme.colors.primary700,
              duration: 0.4,
            },
            ""
          )
          .to(logoRef.current, {
            duration: 0.3,
            color: isHomePage ? theme.colors.white : theme.colors.primary700,
          })
          .to(menuRef.current, { x: "100%", opacity: 0, duration: 0.6 })
          .to(
            menuLayerRef.current,
            {
              x: "100%",
              duration: 0.3,
              onComplete: () => setIsCloseAnimationPlaying(false),
            },
            "-=.4"
          )
          .to(
            [menuRef.current.children],
            {
              opacity: 1,
              duration: 0,
            },
            "+=.5"
          );

        closeAnimationTL.current.timeScale(1.5);
        closeAnimationTL.current.play();
      }
    }
  }, [isMenuOpen]);

  useEffect(() => {
    if (!isMatchMediaMinWidth(bp.medium)) {
      setIsMenuOpen(false);
    }
  }, [setIsMenuOpen]);

  return (
    <StyledHeader is_home_page={isHomePage} curr_page={location.pathname}>
      <Container>
        <nav className="wrapper" ref={wrapperRef}>
          <div className="logo" ref={logoRef} style={{ color: rightLogoColor }}>
            Polymorph Labs
          </div>
          <div className="layer" aria-hidden="true" ref={menuLayerRef}></div>
          <ul className="nav-links" ref={menuRef}>
            <li>
              <Link
                to="/"
                data-hover-text="Home"
                onClick={() => setIsMenuOpen(false)}>
                Home
              </Link>
            </li>
            <li>
              <Link
                to="/about"
                data-hover-text="About"
                onClick={() => setIsMenuOpen(false)}>
                About
              </Link>
            </li>
            <li>
              <Link
                to="/work"
                data-hover-text="Our Work"
                onClick={() => setIsMenuOpen(false)}>
                Our Work
              </Link>
            </li>
            <li>
              <Link
                to="/services"
                data-hover-text="Services"
                onClick={() => setIsMenuOpen(false)}>
                Services
              </Link>
            </li>
            <li>
              <Link
                to="/contact-us"
                data-hover-text="Contact Us"
                onClick={() => setIsMenuOpen(false)}>
                Contact Us
              </Link>
            </li>
          </ul>
          <button
            className="toggle-btn"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            ref={toggleBtnRef}>
            <span
              aria-hidden="true"
              style={{
                background: isHomePage
                  ? theme.colors.white
                  : theme.colors.primary700,
              }}></span>
            <span
              aria-hidden="true"
              style={{
                background: isHomePage
                  ? theme.colors.white
                  : theme.colors.primary700,
              }}></span>
            <span
              aria-hidden="true"
              style={{
                background: isHomePage
                  ? theme.colors.white
                  : theme.colors.primary700,
              }}></span>
          </button>
        </nav>
      </Container>
    </StyledHeader>
  );
};
