import styled from "styled-components";
import { theme, rem, alpha, flexCenter, bp, mq, size, pseudo, flexColumn } from "styles/Utilities.styled";

export const StyledStatistics = styled.section`
    margin-top: 6rem;
    padding: 5rem 0;
    background-color: ${theme.colors.primary600};
    color: ${theme.colors.white};

    .sub-heading {
        color: ${theme.colors.gray300};
        font-weight: 400;
    }

    .heading {
        color: ${theme.colors.white};
    }

    .cards-container {
        ${flexColumn};
        align-items: center;
        gap: 1.5rem;
        margin-top: 2rem;
        /* outline: 1px solid red; */

        ${mq(bp.small)} {
            flex-flow: row wrap;
            justify-content: center;
        }
    }

    .card {
        position: relative;
        ${flexCenter};
        flex-direction: column;
        gap: .5rem;
        width: min(100%, ${rem(220)});
        min-height: ${rem(210)};
        color: ${theme.colors.white}; 
        background-color: transparent;
        text-align: center;
        border: 2px solid ${alpha(theme.colors.white, .2)};
        border-radius: 10px;
        transition: color .4s, background-color .4s;
        overflow: hidden;

        &::before,
        &::after {
            ${pseudo};
        }
        
        &::before {
            top: 36%;
            left: 55%;
            transform: translateX(-50%);
            ${size('190%')};
            clip-path: circle();
        }     

        &::after {
            top: 40%;
            left: 50%;
            transform: translateX(-50%);
            ${size('190%')};
            background-color: ${theme.colors.white};
            clip-path: circle();
            transition: transform .4s, border-color .4s;
        }

        &:hover,
        &:focus {
            border-color: transparent;
            
            &::after {
                transform: scale(2.3);
            }
            
            .card-icon {
                fill: ${theme.colors.primary600};
                stroke: ${theme.colors.primary600};
                z-index: 2;
            }
        }
        
        &-icon {
            font-size: 2.5rem;
            transition: fill .3s, color .3s;
        }
        
        &-amount {
            margin-top: 1rem;
            font-size: ${rem(45)};
            color: ${theme.colors.primary600};
            z-index: 2;
        }
        
        &-text {
            color: ${theme.colors.primaryDesat400};
            z-index: 2;
        }
    }
    
    .card:nth-child(1) {
        &::before {
            background-color: ${theme.colors.accent400};
        }
    }
    .card:nth-child(2) {
        &::before {
            background-color: ${theme.colors.primary400};
        }
    }
    .card:nth-child(3) {
        &::before {
            background-color: ${theme.colors.accent3};
        }
    }
    .card:nth-child(4) {
        &::before {
            background-color: ${theme.colors.accent2};
        }
    }
    `;