import EmpomartLogo from "assets/partners/empomart.png"
import ShortchangedLogo from "assets/partners/shortchanged.jpg"
import SuperfluidLabsLogo from "assets/partners/superfluidlabs.svg"
import PAQLogo from "assets/partners/paq.png"
import PremierSolutionsLogo from "assets/partners/premier-chauffeur.png"
import PenPlusBytesLogo from "assets/partners/penplusbytes.png"
import GMESLogo from "assets/partners/gmes.png"
import ECGLogo from "assets/partners/ecg.jpg"

export const partners = [
    {
        id: 1,
        name: "ECG",
        image: ECGLogo
    },
    {
        id: 2,
        name: "Shortchanged",
        image: ShortchangedLogo
    },
    {
        id: 3,
        name: "Superfluid Labs",
        image: SuperfluidLabsLogo
    },
    {
        id: 4,
        name: "PAQ Technologies",
        image: PAQLogo
    },
    {
        id: 5,
        name: "Premier Solutions",
        image: PremierSolutionsLogo
    },
    {
        id: 6,
        name: "PenPlusBytes",
        image: PenPlusBytesLogo
    },
    {
        id: 7,
        name: "GMES & Africa",
        image: GMESLogo
    },
    {
        id: 8,
        name: "Empomart",
        image: EmpomartLogo
    }
]