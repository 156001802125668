
export const portfolio = [
    {
        id: 1,
        title: 'Healthy Eats',
        image: require("assets/portfolio/g-1.webp"),
        spanTwoCols: true,
    },
    {
        id: 2,
        title: 'Athena app',
        image: require("assets/portfolio/g-3.webp"),
        spanTwoCols: false,
    },
    {
        id: 3,
        title: "Terra",
        image: require("assets/portfolio/g-5.webp"),
        spanTwoCols: false,
    },
    {
        id: 4,
        title: "Apollo Healthcare",
        image: require("assets/portfolio/g-6.webp"),
        spanTwoCols: false,
    },
    {
        id: 5,
        title: "Traffika",
        image: require("assets/portfolio/g-2.webp"),
        spanTwoCols: true,
    },
    {
        id: 6,
        title: "Moniez app",
        image: require("assets/portfolio/g-22.webp"),
        spanTwoCols: false,
    },
    {
        id: 7,
        title: "Demeter",
        image: require("assets/portfolio/g-12.webp"),
        spanTwoCols: true,
    },
    {
        id: 8,
        title: "Presby app",
        image: require("assets/portfolio/g-18.webp"),
        spanTwoCols: true,
    },
    {
        id: 9,
        title: "Poseidon Delivery",
        image: require("assets/portfolio/g-8.webp"),
        spanTwoCols: false,
    },
    {
        id: 10,
        title: "Ocean Isle",
        image: require("assets/portfolio/g-20.webp"),
        spanTwoCols: false,
    },
    {
        id: 11,
        title: "Green veggies",
        image: require("assets/portfolio/g-7.webp"),
        spanTwoCols: true,
    },
    {
        id: 12,
        title: "Juna maps",
        image: require("assets/portfolio/g-13.webp"),
        spanTwoCols: false,
    },
    {
        id: 13,
        title: "Zeus fitness",
        image: require("assets/portfolio/g-9.webp"),
        spanTwoCols: false,
    },
    {
        id: 14,
        title: "Good ocean",
        image: require("assets/portfolio/g-21.webp"),
        spanTwoCols: false,
    },
    {
        id: 15,
        title: "Vitalax Pro",
        image: require("assets/portfolio/g-23.webp"),
        spanTwoCols: false,
    },
]