import styled from "styled-components";
import { theme, rem, size, mq, bp, flexCenter, flexColumn } from "styles/Utilities.styled";

export const StyledHeader = styled.header`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: 1.5rem 0;
    z-index: 99;
    
    .wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        color: ${theme.colors.white};
    }

    .logo {
        display: inline-block;
        font-size: clamp(1.1rem, 4vw, 1.7rem);
        font-weight: 700;
        z-index: 11;
        color: inherit;
        /* outline: 1px solid; */
    }

    .layer {
        background: ${theme.colors.accent400};
        width: 100%;

        ${mq(bp.medium)} {
            display: none;
        }
    }
    
    .layer, 
    .nav-links {
        position: fixed;
        top: 0; 
        right: 0;
        bottom: 0;
        z-index: 5;
    }

    .nav-links {
        ${flexCenter}
        flex-direction: column;
        gap: 1rem;
        background: ${theme.colors.gray100};
        width: 100%;
        
        ${mq(bp.medium)} {
            position: relative;
            flex-direction: row;
            gap: 2rem;
            background: none;
            width: fit-content;
            transform: none !important;
            opacity: 1 !important;
        }
        
        li {
            font-size: clamp(1.2rem, 4vw, 1.3rem);
            overflow: hidden;
            
            ${mq(bp.medium)} {
                font-size: ${rem(15)};
                font-weight: 300;
                opacity: 1 !important;
            }

            // They all need to be adjusted because they have different number of characters
            &:hover {
                a {
                    transform: translateY(-145%) skewY(20deg);
                }
            }
            
            &:nth-child(1):hover {
                a::before {
                    transform: translateY(182%) skewY(-20deg);
                }
            }

            &:nth-child(2):hover {
                a::before {
                    transform: translateY(185%) skewY(-20deg);
                }
            }

            &:nth-child(4):hover {
                a::before {
                    transform: translateY(200%) skewY(-20deg);
                }
            }

            &:nth-child(3):hover {
                a::before {
                    transform: translateY(208%) skewY(-20deg);
                }
            }

            &:nth-child(5):hover {
                a::before {
                    transform: translateY(212%) skewY(-20deg);
                }
            }
            
            a {
                display: block;
                position: relative;
                color: ${theme.colors.primary700};
                white-space: nowrap;
                letter-spacing: .03em;
                transition: transform 1s ${theme.ease.slowStart}, color 1s;

                ${mq(bp.medium)} {
                    color: ${({ is_home_page }) => is_home_page ? theme.colors.white : theme.colors.primary700};
                    font-weight: ${({ is_home_page }) => !is_home_page && 400};
                }
                
                &::before {
                    content: attr(data-hover-text);
                    position: absolute;
                    color: ${theme.colors.accent400};
                    transform: translateY(140%) skewY(40deg);
                    transform-origin: left center;
                    transition: transform 1s ${theme.ease.slowStart};
                }
            }

        }

        //? Refactor into a function later ??
        li:nth-child(1) {
            a {
                color: ${({ curr_page }) => curr_page === '/' && theme.colors.accent500}
            }
        }
        
        li:nth-child(2) {
            a {
                color: ${({ curr_page }) => curr_page === '/about' && theme.colors.accent500}
            }
        }
        
        li:nth-child(3) {
            a {
                color: ${({ curr_page }) => curr_page === '/work' && theme.colors.accent500}
            }
        }
        
        li:nth-child(4) {
            a {
                color: ${({ curr_page }) => curr_page === '/services' && theme.colors.accent500}
            }
        }

        li:nth-child(5) {
            a {
                color: ${({ curr_page }) => curr_page === '/contact-us' && theme.colors.accent500}
            }
        }
    }

    .toggle-btn {
        ${flexColumn};
        justify-content: space-evenly;
        align-items: center;
        position: relative;
        ${size(rem(25))};
        background: none;
        cursor: pointer;
        border: none;
        z-index: 11;
        /* outline: 1px solid red; */

        ${mq(bp.medium)} {
            display: none;
        }
        
        span {
            position: absolute;
            background-color: ${theme.colors.white};
            ${size("inherit", '2.5px')};
            border-radius: 5px;
            transition: all 0.6s ${theme.ease.quickStartAndEnd};
            transform-origin: center;

            &:first-child {
                transform: translateY(-8px);
            }
            
            &:nth-child(2) {
                top: 50%;
                transform: translateY(-50%);
            }
            
            &:last-child {
                transform: translateY(8px);
            }
        }
    }
`;