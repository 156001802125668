import { Hero } from "./sections/Hero";
import { About } from "./sections/About";
import { Services } from "./sections/Services";
import { Portfolio } from "./sections/Portfolio";
import { Statistics } from "./sections/Statistics";
import { Testimonials } from "./sections/Testimonials";

export const Home = () => {
  return (
    <>
      <Hero />
      <About />
      <Services />
      <Portfolio />
      <Statistics />
      <Testimonials />
    </>
  );
};
