import styled from "styled-components";
import { rem, size, theme, alpha, mq, bp, pseudo, flexColumn } from "styles/Utilities.styled";

export const StyledAbout = styled.section`
    margin-top: 5rem;
    /* outline: 1px solid red; */

    ${mq(bp.medium)} {
        margin-top: 8rem;
    }

    .wrapper {
        
        ${mq(bp.medium)} {
            display: flex;
            gap: 5rem;

            & > div:nth-child(1) {
                flex-basis: 60%;
            }
        }
        
        ${mq(bp.large)} {
            gap: 2rem;
        }
    }

    .text-content {

        ${mq(bp.medium)} {
            padding: calc(12.5% / 2) 0;
            /* outline: 1px solid brown; */
        }
    }
    
    
    .heading:nth-child(3) {
        margin-top: 0;
    }
    
    .summary {
        ${mq(bp.medium)} {
            margin-left: 0;
        }
    }
    
    .sub-heading,
    .heading,
    .summary {
        ${mq(bp.medium)} {
            text-align: left;
        }
    }
    
    .about-cta {
        display: block;
        width: fit-content;
        margin: 1rem auto;

        ${mq(bp.medium)} {
            margin-left: 0;
            margin-top: 2rem;
        }
    }

    .images-container {
        ${flexColumn};
        gap: 1rem;
        margin-top: 2rem;
        
        ${mq(bp.small)} {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: .6fr 1fr;
            margin-top: 3.5rem;
            gap: 1.3rem;
        }
        
        ${mq(bp.medium)} {
            width: fit-content;
            grid-template-columns: ${rem(210)} ${rem(220)};
            /* outline: 1px solid red; */
        }
        
        ${mq(bp.large)} {
            grid-template-columns: ${rem(250)} ${rem(300)};
        }
    }

    .img-wrapper {
        position: relative;
        border-radius: 80px 0 80px 0;

        ${mq(bp.small)} {
            border-radius: 50px 0 50px 0;
        }

        &::before,
        &::after {
            ${pseudo};
            border-radius: inherit;
        }

        &::before {
            ${size('100%')};
            z-index: 2;
        }
        
        &::after {
            inset: 0;
            background-color: ${alpha(theme.colors.black, .2)};
            z-index: 1;
        }
        
        img {
            border-radius: inherit;
            ${size('100%')};
        }
    }

    .img-wrapper:nth-child(1) {

        ${mq(bp.small)} {
            grid-column: 2 / 3;
            grid-row: 1 / 3;
            ${size('70%', '100%')};
            margin-right: auto;
            margin-top: -2rem;
            align-self: start;
            
            img {
                object-position: right;
            }
        }
        
        ${mq(bp.medium)} {
            ${size('100%', '100%')};
            margin-top: -3rem;
        }
 
        &::before {
            top: -5%;
            left: -3%;
            border: 2px dashed;
            border-color: ${alpha(theme.colors.gray300, .7)} ${theme.colors.white} ${theme.colors.white} ${alpha(theme.colors.gray300, .7)};
            
            ${mq(bp.small)} {
                top:  -3%;
                left: -7%;
                border-color: transparent ${alpha(theme.colors.white, .7)} ${alpha(theme.colors.white, .7)} transparent;
                border-style: solid;
            }
        }
        
    }

    .img-wrapper:nth-child(2) {
        
        ${mq(bp.small)} {
            grid-column: 1 / 2;
            grid-row: 1 / 2;
            ${size('70%', 'auto')};
            margin-left: auto;
            margin-top: 2rem;
            align-self: end;
        }
        
        ${mq(bp.medium)} {
            ${size('100%', '80%')};
            margin-top: 4rem;
        }
        
        &::before {
            
            ${mq(bp.small)} {
                top: -8%;
                left: -8%;
                width: 60%;
                background-color: ${alpha(theme.colors.accent400, .7)};
                z-index: -1;
            }
        }
    }
    
    .img-wrapper:nth-child(3) {
        
        ${mq(bp.small)} {
            grid-column: 1 / 2;
            grid-row: 2 / 3;
            ${size('70%', '110%')};
            margin-left: auto;
        }

        ${mq(bp.medium)} {
            ${size('100%')};
        }

        &::before {
            top: 5%;
            left: 3%;
            border: 2px dashed;
            border-color: ${theme.colors.accent400} ${alpha(theme.colors.accent400, .7)} ${alpha(theme.colors.accent400, .7)} ${theme.colors.accent400};

            ${mq(bp.small)} {
                top: 35%;
                left: -10%;
                ${size('70%', '80%')};
                background-color: ${alpha(theme.colors.accent3, .7)};
                border: none;
                border-radius: 0 50px 0 50px;
                z-index: -1;
            }
        }
    }
`;