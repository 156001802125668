import { Container } from "styles/components/Container.styled";
import { SectionHeading } from "styles/components/SectionText.styled";
import { StyledTermsOfService } from "./TermsOfService.styled";

export const TermsOfService = () => {
  return (
    <StyledTermsOfService>
      <Container>
        <div className="text-content">
          <SectionHeading className="heading">
            Terms of Service for Polymorph Labs Ghana Limited
          </SectionHeading>
          <p>
            Welcome to Polymorph Labs Ghana Limited("us", "we", or "our"). These
            Terms of Service (the "Terms") govern your use of our website
            polymorphlabs.io (the "Site") and our software engineering services
            (the "Services"). By using the Site or the Services, you agree to be
            bound by these Terms.
          </p>
          <h3>Use of the Site</h3>
          <p>
            You may use the Site only for lawful purposes and in accordance with
            these Terms. You must not use the Site in any way that violates any
            applicable federal, state, local, or international law or
            regulation.
          </p>

          <h3>Intellectual Property</h3>

          <p>
            The Site and the Services and their entire contents, features, and
            functionality (including but not limited to all information,
            software, text, displays, images, video, and audio, and the design,
            selection, and arrangement thereof) are owned by us, our licensors,
            or other providers of such material and are protected by the
            Republic of Ghana and international copyright, trademark, patent,
            trade secret, and other intellectual property or proprietary rights
            laws.
          </p>

          <p className="mt">
            You must not reproduce, distribute, modify, create derivative works
            of, publicly display, publicly perform, republish, download, store,
            or transmit any of the material on our Site or the Services, except
            as follows:
          </p>

          <ul>
            <li>
              Your computer may temporarily store copies of such materials in
              RAM incidental to your accessing and viewing those materials.
            </li>
            <li>
              You may store files that are automatically cached by your Web
              browser for display enhancement purposes.
            </li>
            <li>
              You may print or download one copy of a reasonable number of pages
              of the Site for your own personal, non-commercial use and not for
              further reproduction, publication, or distribution.
            </li>
          </ul>

          <h3>Prohibited Uses</h3>
          <p>
            You may use the Site and the Services only for lawful purposes and
            in accordance with these Terms. You agree not to use the Site or the
            Services:
          </p>

          <ul>
            <li>
              In any way that violates any applicable federal, state, local, or
              international law or regulation.
            </li>
            <li>
              To engage in any conduct that restricts or inhibits anyone's use
              or enjoyment of the Site or the Services, or which, as determined
              by us, may harm us or users of the Site or the Services or expose
              them to liability.
            </li>
            <li>
              To impersonate or attempt to impersonate us, our employee, another
              user, or any other person or entity.
            </li>
            <li>
              To transmit, or procure the sending of, any advertising or
              promotional material, including any "junk mail", "chain letter",
              "spam", or any other similar solicitation.
            </li>
            <li>
              To use any robot, spider, or other automatic devices, process, or
              means to access the Site or the Services for any purpose,
              including monitoring or copying any of the material on the Site or
              the Services.
            </li>
            <li>
              To use any manual process to monitor or copy any of the material
              on the Site or the Services or for any other unauthorised purpose
              without our prior written consent.
            </li>
          </ul>

          <h3>Disclaimers</h3>
          <p>
            The Site and the Services are provided on an "as is" and "as
            available" basis, without any warranties of any kind, either express
            or implied, including without limitation any warranties of
            merchantability, fitness for a particular purpose, title, or
            non-infringement.
          </p>

          <h3>Limitation of Liability</h3>
          <p>
            In no event shall we or our affiliates, licensors, service
            providers, employees, agents, officers, or directors be liable for
            any direct, indirect, punitive, incidental, special, or
            consequential damages arising out of or in any way connected with
            your use of the Site or the Services, whether based on contract,
            tort, strict liability, or any other legal theory, even if we have
            been advised of the possibility of such damages.
          </p>

          <p>Last updated: 24th February 2023</p>
        </div>
      </Container>
    </StyledTermsOfService>
  );
};
