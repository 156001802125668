import styled from "styled-components"
import { bp, mq, theme, rem, alpha, flexCenter, absoluteCenter, flexColumn } from "styles/Utilities.styled";

export const StyledTheTeam = styled.section`
    position: relative;
    margin-top: 7rem;
    padding: 5rem 0;
    background-color: ${theme.colors.gray100};

    .images-container {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: 1fr;
        margin-top: 4rem;
        border-radius: 8px;
        overflow: hidden;

        ${mq(bp.small)} {
            grid-template-columns: repeat(4, 1fr);
        }

        ${mq(bp.medium)} {
            grid-template-columns: repeat(5, 1fr);
        }

        ${mq(bp.large)} {
            grid-template-columns: repeat(6, 1fr);
        }

        .img-wrapper {
            position: relative;
            /* min-height: ${rem(216)}; */
            max-height: ${rem(250)};
            width: 100%;
            overflow: hidden;
            /* outline: 1px solid red; */

            ${mq(bp.small)} {
                max-height: ${rem(290)};
            }

            &.career {
                ${flexCenter};
                position: relative;
                padding: .7rem;
                background-color: ${alpha(theme.colors.accent400, .4)};

                ${mq(bp.small)} {
                    ${flexColumn};
                    min-height: ${rem(250)};
                }

                &:hover{
                    .cta {
                        svg:first-child {
                            transform: translate(-50%, -50%);
                        }
                        svg:last-child {
                            transform: translate(220%, -50%);
                        }
                    }
                }

                p {
                    text-align: center;
                }
                
                p:nth-child(1) {
                    color: ${theme.colors.primary700};
                    font-size: ${rem(15)};
                    font-weight: 700;

                    ${mq(bp.small)} {
                        font-size: ${rem(18)};
                    }
                }

                p:nth-child(2) {
                    display: none;
                    margin-top: .8rem;
                    font-size: ${rem(14)};
                    font-weight: 500;
                    color: ${theme.colors.primary400};
                    
                    ${mq(bp.small)} {
                        display: block;
                    }
                }

                .cta {
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    padding: 1rem;
                    background: ${theme.colors.accent2};
                    overflow: hidden;

                    ${mq(bp.small)} {
                        padding: 1.5rem;
                    }

                    svg {
                        position: absolute;
                        ${absoluteCenter};
                        font-size: 1.1rem;
                        color: ${theme.colors.white};
                        transition: transform .3s ${theme.ease.almostLinear};

                        ${mq(bp.small)} {
                            font-size: 1.3rem;
                        }
                    }

                    svg:first-child {
                        /* opacity: 0; */
                        transform: translate(-220%, -50%);
                    }

                    svg:last-child {
                        /* opacity: 1; */
                    }
                }
            }

            img {
                transition: transform 1s ${theme.ease.almostLinear};
                object-postion: center;
                object-fit: cover;
                height: 100%;
            }
            
            &:hover,
            &:focus {
                img {
                    transform: scale(1.2);
                }
                
                .text-content {
                    opacity: 1;

                    p {
                        opacity: 1;
                        transform: translateX(0);
                    }
                }
            }
            
            .text-content {
                ${flexColumn};
                justify-content: space-between;
                position: absolute;
                inset: 0;
                padding: 1.3em .8em;
                font-size: clamp(.8rem, 4vw, 1rem);
                color: ${theme.colors.white};
                background: ${alpha(theme.colors.primaryDesat500, .85)};
                z-index: 1;
                opacity: 0;
                transition: opacity .5s ${theme.ease.almostLinear};

                p {
                    opacity: 0;
                    transform: translateX(5%);
                }
                
                p:first-child {
                    text-transform: capitalize;
                    transition: opacity .4s .3s  ${theme.ease.almostLinear}, transform .4s .3s;
                }
                
                p:last-child {
                    text-transform: uppercase;
                    font-size: clamp(.5rem, 3.5vw, .8rem);
                    transition: opacity .4s .5s  ${theme.ease.almostLinear}, transform .4s .5s;
                }
            }
        }
    }
`;