import styled from "styled-components";
import { theme, rem, alpha, mq, bp, size, flexColumn } from "styles/Utilities.styled";

export const StyledPortfolio = styled.section`
    margin-top: 8rem;
    overflow-x: hidden; 
    
    .sub-heading,
    .heading {
        text-align: center;
    }

    .cards-container {
        ${flexColumn};
        gap: 2rem;
        margin-top: 3rem;

        ${mq(bp.small)} {
            flex-flow: row wrap;
            justify-content: center;
        }

        ${mq(bp.medium)} {
            display: grid;
            grid-template-columns: repeat(6, 1fr);
            grid-template-rows: 1fr .7fr;
            gap: 1.3rem;
            width: 85%;
            margin-inline: auto;
            /* outline: 1px solid red; */
        }
    }

    .card {
        position: relative;
        border-radius: 50px 10px 10px;
        background-color: ${theme.colors.gray100};
        box-shadow: 0 0 10px ${alpha(theme.colors.primary700, .2)};
        overflow: hidden;
        
        ${mq(bp.small)} {
            flex: 1 1 35%;
        }
        
        ${mq(bp.medium)} {
            background-color: transparent;
            border-radius: 50px 0 50px 0;
            box-shadow: 0 0 6px ${alpha(theme.colors.primary700, .3)};

            &:hover,
            &:focus-within {
                .card-text-content {
                    background-color: ${alpha(theme.colors.primaryDesat500, .8)};
                    z-index: 1;
                }

                .card-title {
                    opacity: 1;
                    transform: translateY(0);
                }
                
                .card-text {
                    opacity: 1;
                    transform: translateY(0);
                }
                
                .card-cta {
                    opacity: 1;
                    transform: translateY(0);
                }

                img {
                    transform: scale(1.1);
                }
            }
        }

        &-img-wrapper {
            overflow: hidden;
            border-radius: 0 0 50px 0;

            ${mq(bp.medium)} {
                border-radius: inherit;
                ${size('100%')};
            }

            &:hover {
                img {
                    transform: scale(1.1);
                }
            }

            img {
                transition: transform .5s ease-in-out;
                ${size('100%')};
            }
        }

        &-text-content {
            padding: 1.5rem 1.5rem 1rem;
            text-align: center;
            transition: background-color .5s;

            ${mq(bp.medium)} {
                ${flexColumn};
                justify-content: center;
                align-items: center;
                position: absolute;
                inset: 0;
                color: ${theme.colors.white};
                z-index: -1;
            }
        }

        &-title {
            font-size: ${rem(19)};

            ${mq(bp.medium)} {
                font-size: ${rem(21)};
                opacity: 0;
                transform: translateY(-10%);
                transition: transform .5s .4s, opacity .5s .4s;
            }
        }
        
        &-text {
            margin-top: .5rem;
            font-size: ${rem(15)};
            font-weight: 300;
            
            ${mq(bp.medium)} {
                font-weight: 400;
                opacity: 0;
                transform: translateY(-10%);
                transition: transform .5s .2s, opacity .5s .2s;
            }
        }

        &-cta {
            margin-top: .5rem;
            color: ${theme.colors.primary400};
            background: none;

            ${mq(bp.medium)} {
                /* padding-right: 2.8em;  */
                color: ${theme.colors.primary400};
                background-color: ${theme.colors.white};
                opacity: 0;
                transform: translateY(10%);
                transition: transform .5s .2s, opacity .5s .2s;
            }
        }
    }

    .card:nth-child(1),
    .card:nth-child(2) {
        ${mq(bp.medium)} {
            .card-title {
                font-size: ${rem(27)};
            }

            .card-text {
                margin-top: 1rem;
            }

            .card-cta {
                margin-top: 5rem;
            }
        }
        
        ${mq(bp.large)} {
            .card-cta {
                margin-top: 6rem;
            }
        }
    }

    .card:nth-child(3),
    .card:nth-child(4),
    .card:nth-child(5) {
        ${mq(bp.medium)} {
            .card-cta {
                margin-top: 1.5rem;
            }
        }

        ${mq(bp.large)} {
            .card-cta {
                margin-top: 2rem;
            }
        }
    }

    .card:nth-child(1) {
        ${mq(bp.medium)} {
            grid-column: 1 / span 3;
            grid-row: 1 / 2; 
        }
    }
    .card:nth-child(2) {
        ${mq(bp.medium)} {
            grid-column: 4 / span 3;
            grid-row:  1 / 2; 
        }   
    }
    .card:nth-child(3) {
        ${mq(bp.medium)} {
            grid-column: 1 / span 2;
            grid-row: 2 / 3; 
        }   
    }
    .card:nth-child(4) {
        ${mq(bp.medium)} {
            grid-column: 3 / span 2;
            grid-row: 2 / 3; 
        }   
    }
    .card:nth-child(5) {
        ${mq(bp.medium)} {
            grid-column: 5 / span 2;
            grid-row: 2 / 3; 
        }   
    }

    .portfolio-cta {
        display: block;
        padding-block: .9em;
        width: fit-content;
        margin: 2rem auto;
    }
`;