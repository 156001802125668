import { Container } from "styles/components/Container.styled";
import { StyledError } from "./Error.styled";
import { ReactComponent as ErrorIllustration } from "assets/error_illustration.svg";
import { LinkAButton } from "components/LinkButton";
import { theme } from "styles/Utilities.styled";

export const Error = () => {
  return (
    <StyledError>
      <Container>
        <div className="error-img-wrapper">
          <ErrorIllustration />
        </div>

        <div className="text-content">
          <h1 className="heading">Oops!!</h1>
          <h1 className="sub-heading">Where are we?</h1>
          <p className="summary">
            The page you are looking for was removed, renamed or might not
            exist.
          </p>
        </div>

        <LinkAButton
        className="cta"
          to="/"
          text={"Go Home"}
          color={theme.colors.primary400}
          border_color={theme.colors.primary400}
        />
      </Container>
    </StyledError>
  );
};
