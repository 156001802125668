import styled from "styled-components";
import { bp, mq } from "styles/Utilities.styled";

export const StyledTermsOfService = styled.section`
  margin-top: 8rem;

  ${mq(bp.small)} {
    margin-top: 12rem;
  }

  .heading {
    text-align: left;
    font-weight: 700;
  }

  .text-content {
    max-width: 55ch;
    margin-inline: auto;

    ${mq(bp.medium)} {
      max-width: 60ch;
    }

    p:nth-child(2) {
      margin-top: 1.5rem;
    }

    h3 {
      margin-top: 2rem;

      & + p {
        margin-top: 0.75rem;
      }
    }

    p.mt {
      margin-top: 0.75rem;
    }

    ul {
      margin-top:1rem;
      list-style-type: disc;
      
      li {
        margin-left: 1rem;
      }
    }

    p:last-child {
      margin-top: 3rem;
    }
  }
`;
