import { Link } from "react-router-dom";

import {
  FaInstagram,
  FaFacebookF,
  FaLinkedinIn,
  FaTwitter,
} from "react-icons/fa";
import { IoCallSharp } from "react-icons/io5";
import { MdEmail, MdLocationOn } from "react-icons/md";

import { StyledFooter } from "./Footer.styled";
import { Container } from "../../styles/components/Container.styled";
import { Contact } from "../../pages/Home/sections/Contact";
import { useIsContactUsPage } from "hooks/useIsContactUsPage";

export const Footer = () => {
  const isContactUsPage = useIsContactUsPage();
  return (
    <StyledFooter is_contact_us_page={isContactUsPage}>
      {!isContactUsPage && <Contact />}
      <Container>
        <div className="footer-wrapper">
          <div className="footer-about">
            <Link to="/" className="footer-about-logo">
              Polymorph Labs
            </Link>
            <p className="footer-about-text">
              <span>
                It takes two simple steps to be the best on the market.
              </span>
              <span>Step 1: Have a good idea.</span>
              <span>Step 2: Contact us.</span>
            </p>
            <ul className="social-icons">
              <li>
                <Link
                  to="https://www.facebook.com/profile.php?id=100090411352433"
                  aria-label="Facebook"
                  title="Facebook">
                  <FaFacebookF className="icon" />
                </Link>
              </li>
              <li>
                <Link
                  to="https://www.instagram.com/polymorph.labs"
                  aria-label="Instagram"
                  title="Instagram">
                  <FaInstagram className="icon" />
                </Link>
              </li>
              <li>
                <Link
                  to="https://twitter.com/polymorphlabsgh?s=21&t=v0OPbd9yx2t-mvtsmkFEfg"
                  aria-label="Twitter"
                  title="Twitter">
                  <FaTwitter className="icon" />
                </Link>
              </li>
              <li>
                <Link
                  to="https://www.linkedin.com/company/polymorph-labs"
                  aria-label="LinkedIn"
                  title="LinkedIn">
                  <FaLinkedinIn className="icon" />
                </Link>
              </li>
            </ul>
          </div>

          <ul className="nav-links">
            <li className="footer-heading">Company</li>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="about">About</Link>
            </li>
            <li>
              <Link to="work">Our Work</Link>
            </li>
            <li>
              <Link to="services">Services</Link>
            </li>
            <li>
              <Link to="#">Jobs</Link>
            </li>
          </ul>

          <ul className="nav-links">
            <li className="footer-heading">Services</li>
            <li>
              <Link to="#">UI/UX design</Link>
            </li>
            <li>
              <Link to="#">Mobile dev.</Link>
            </li>
            <li>
              <Link to="#">Web dev.</Link>
            </li>
            <li>
              <Link to="#">Backend dev.</Link>
            </li>
            <li>
              <Link to="#">IT Consulting</Link>
            </li>
            <li>
              <Link to="#">Dev Ops</Link>
            </li>
          </ul>

          <ul className="nav-links">
            <li className="footer-heading">Resources</li>
            <li>
              <Link to="privacy-policy">Privacy Policy</Link>
            </li>
            <li>
              <Link to="terms-of-service">Terms of service</Link>
            </li>
            <li>
              <Link to="#">Help & FAQ</Link>
            </li>
          </ul>

          <ul className="footer-contact">
            <li>
              <h4 className="footer-heading">Get in touch</h4>
            </li>
            <li className="footer-contact-item">
              <IoCallSharp className="icon" />
              <div className="wrapper">
                <p>+233 20 404 5782</p>
              </div>
            </li>
            <li className="footer-contact-item">
              <MdEmail className="icon" />
              <div className="wrapper">
                <Link to="mailto:info@polymorphlabs.io">
                  info@polymorphlabs.io
                </Link>
              </div>
            </li>
            <li className="footer-contact-item">
              <MdLocationOn className="icon" />
              <div className="wrapper">
                <p>MRF5+P5P NII NORTEY CRESCENT, HAATSO</p>
                <p>09:00 AM - 05:00 PM GMT +0</p>
              </div>
            </li>
          </ul>
        </div>

        <div className="copyright">
          <p>
            Copyright &copy; 2021 - {new Date().getFullYear()}
            &nbsp;<span>Polymorph Labs</span>.
          </p>
          <p>All rights reserved.</p>
        </div>
      </Container>
    </StyledFooter>
  );
};
