import { StyledPortfolio } from "./Portfolio.styled";
import { Container } from "styles/components/Container.styled";
import { LinkButton, LinkAButton } from "components/LinkButton";
import { theme } from "styles/Utilities.styled";
import {
  SectionHeading,
  SectionSubHeading,
} from "styles/components/SectionText.styled";

export const Portfolio = () => {
  return (
    <StyledPortfolio>
      <Container>
        <SectionSubHeading className="sub-heading">
          Our Portfolio
        </SectionSubHeading>
        <SectionHeading className="heading">
          Some of the work we are most proud of
        </SectionHeading>
        <div className="cards-container">
          <div className="card">
            <div className="card-img-wrapper">
              <img
                src={require("assets/portfolio/asset-1.jpg")}
                width={288}
                height={216}
                loading="lazy"
                alt=""
              />
            </div>
            <div className="card-text-content">
              <h4 className="card-title">Empomart</h4>
              <p className="card-text">
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Nostrum, id.
              </p>
              <LinkAButton className="card-cta" text="View Project" to="/" />
            </div>
          </div>
          <div className="card">
            <div className="card-img-wrapper">
              <img
                src={require("assets/portfolio/asset-2.jpg")}
                width={288}
                height={216}
                loading="lazy"
                alt=""
              />
            </div>
            <div className="card-text-content">
              <h4 className="card-title">Here ‘n now</h4>
              <p className="card-text">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Odio,
                iure.
              </p>
              <LinkAButton className="card-cta" text="View Project" to="/" />
            </div>
          </div>
          <div className="card">
            <div className="card-img-wrapper">
              <img
                src={require("assets/portfolio/asset-3.jpg")}
                width={288}
                height={216}
                loading="lazy"
                alt=""
              />
            </div>
            <div className="card-text-content">
              <h4 className="card-title">Pentecost Companion</h4>
              <p className="card-text">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nihil,
                veniam?
              </p>
              <LinkAButton className="card-cta" text="View Project" to="/" />
            </div>
          </div>
          <div className="card">
            <div className="card-img-wrapper">
              <img
                src={require("assets/portfolio/asset-4.jpg")}
                width={288}
                height={216}
                loading="lazy"
                alt=""
              />
            </div>
            <div className="card-text-content">
              <h4 className="card-title">Ocean State</h4>
              <p className="card-text">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nihil,
                veniam?
              </p>
              <LinkAButton className="card-cta" text="View Project" to="/" />
            </div>
          </div>
          <div className="card">
            <div className="card-img-wrapper">
              <img
                src={require("assets/portfolio/asset-5.jpg")}
                width={288}
                height={216}
                loading="lazy"
                alt=""
              />
            </div>
            <div className="card-text-content">
              <h4 className="card-title">EMRYS</h4>
              <p className="card-text">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nihil,
                veniam?
              </p>
              <LinkAButton className="card-cta" text="View Project" to="/" />
            </div>
          </div>
        </div>
        <LinkButton
          className="portfolio-cta"
          text="Explore Our Work"
          to="/work"
          color={theme.colors.white}
          bg={theme.colors.primary400}
        />
      </Container>
    </StyledPortfolio>
  );
};
