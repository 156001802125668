export const strategy = [
    {
        counter: "01.",
        title: "Discovery",
        body: "In the initial stakeholder meetings, we'll define your business goals by asking the right questions that lead you to strategic answers. A communication plan is then created while budgets and timelines are determined.",
    },
    {
        counter: "02.",
        title: "Research",
        body: "Rigorous research is at the centre of our work. We take into account everything mentioned in the previous meetings in order to find the perfect method of execution for the intended solution. We'll prepare a detailed report for you in the next meeting.",
    },
    {
        counter: "03.",
        title: "Design",
        body: "This is the phase where we ignite your passion with our amazing designs and well-thought-out user flows. If you're not impressed here, there's no way you'll be impressed moving forward. But don't worry, this NEVER happens.",
    },
    {
        counter: "04.",
        title: "Development",
        body: "After approval from you, it's time to be amazed again by how our exceptional devs bring the design concept to life. We use the best practices available and cater for every scenario. No need to break a sweat!",
    },
    {
        counter: "05.",
        title: "Testing",
        body: "While others are mostly in a rush to ship, we approach things differently by utilising Quality Assurance testing to fine-tune things and make sure only bug-free products are shipped.",
    },
    {
        counter: "06.",
        title: "Deployment",
        body: " Your jaws are probably aching from the wide smiles. It's finally time to get the show on the road. We'll still help with migration, auditing and answering your questions super fast!",
    },
]