import { FiPenTool } from "react-icons/fi";
import { BiCodeAlt, BiClipboard } from "react-icons/bi";
import { HiOutlineHome } from "react-icons/hi";

import { Container } from "styles/components/Container.styled";
import { StyledServices } from "./Services.styled";
import { LinkAButton } from "components/LinkButton";
import { theme } from "styles/Utilities.styled";
import {
  SectionHeading,
  SectionSubHeading,
  SectionSummary,
} from "styles/components/SectionText.styled";

export const Services = () => {
  return (
    <StyledServices>
      <span className="decor-1" aria-hidden="true"></span>
      <Container>
        <div className="wrapper">
          <div className="text-content">
            <SectionSubHeading className="sub-heading">
              Our Services
            </SectionSubHeading>
            <SectionHeading className="heading">
              What we can do for you!
            </SectionHeading>
            <SectionSummary className="summary">
              We provide elite services across a variety of fields and use the
              latest technologies.
            </SectionSummary>
            <LinkAButton
              className="services-cta"
              text="All Services"
              to="/services"
              color={theme.colors.primary400}
              border_color={theme.colors.primary400}
            />
          </div>
          <div className="cards-container">
            <div className="card">
              <FiPenTool className="card-icon" />
              <h4 className="card-title"> UI/UX Design</h4>
              <p className="card-text">
                Convert sketches and mockups into prototypes finished with
                interactive and intuitive interface designs.
              </p>
              {/* <LinkTAButton
                className="card-cta"
                text="Read more"
                to="#"
                color={theme.colors.primary400}
              /> */}
            </div>
            <div className="card">
              <BiCodeAlt className="card-icon" />
              <h4 className="card-title">Software Development</h4>
              <p className="card-text">
                Plan, develop and test high-quality web and mobile applications
                using agile methodologies for continuous production.
              </p>
              {/* <LinkTAButton
                className="card-cta"
                text="Read more "
                to="#"
                color={theme.colors.primary400}
              /> */}
            </div>
            <div className="card">
              <BiClipboard className="card-icon" />
              <h4 className="card-title">Business Consulting</h4>
              <p className="card-text">
                We professionally analyse business concepts and processes to
                prepare a viable solution for your business needs.
              </p>
              {/* <LinkTAButton
                className="card-cta"
                text="Read more"
                to="#"
                color={theme.colors.primary400}
              /> */}
            </div>
            <div className="card">
              <HiOutlineHome className="card-icon" />
              <h4 className="card-title">Home Automation</h4>
              <p className="card-text">
                Transform your home or workspace with our thoughtful home
                automation strategies and products.
              </p>
              {/* <LinkTAButton
                className="card-cta"
                text="Read more"
                to="#"
                color={theme.colors.primary400}
              /> */}
            </div>
          </div>
        </div>
      </Container>
      <span className="decor-2" aria-hidden="true"></span>
    </StyledServices>
  );
};
