import React from "react";
import { StyledCircularCard } from "./CircularCard.styled";

export const CircularCard = ({
  icon,
  lightColor,
  deepColor,
  heading,
  cardText,
  textContentSubHeading,
  bannerSubHeading,
  overrideLightColorAlpha = false,
}) => {
  const TheIcon = icon; // need to do this in order to call "TheIcon" as an element
  return (
    <StyledCircularCard
      light_color={lightColor}
      override_light_color_alpha={overrideLightColorAlpha}
      deep_color={deepColor}
      tabIndex={0}
    >
      <h5 className="tc-subheading">{heading}</h5>
      <h4 className="tc-heading">{textContentSubHeading}</h4>
      <div className="icon-wrapper">
        <TheIcon className="icon" />
      </div>
      <div className="text-banner">
        <h4 className="tb-heading">{heading}</h4>
        <h5 className="tb-subheading">{bannerSubHeading}</h5>
      </div>
      <p className="text">{cardText}</p>
    </StyledCircularCard>
  );
};
