import { LinkButton } from "components/LinkButton";
import { Container } from "styles/components/Container.styled";
import { StyledContact } from "./Contact.styled";
import { theme } from "styles/Utilities.styled";

export const Contact = () => {
  return (
    <StyledContact>
      <Container>
        <div className="wrapper">
          <div className="text-content">
            <h3 className="heading">Let's build tomorrow, today.</h3>
            <p className="text">Ready when you are.</p>
          </div>
          <LinkButton
            className="contact-cta"
            text="Talk to us"
            to="/contact-us"
            color={theme.colors.accent500}
            bg={theme.colors.white}
            hover_bg={theme.colors.primary400}
          />
        </div>
      </Container>
    </StyledContact>
  );
};
