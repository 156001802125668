import { StyledPreloader } from "./Preloader.styled";
import PreloaderImage from "assets/preloader.svg";

export const Preloader = () => {
  return (
    <StyledPreloader aria-hidden="true">
      <img src={PreloaderImage} alt="Preloader" />
    </StyledPreloader>
  );
};
