import styled from "styled-components";

import { rem, theme, size, mq, bp, flexColumn } from "styles/Utilities.styled";
import leftDecorImage from "assets/contact-us/asset-1.webp"
import rightDecorImage from "assets/contact-us/asset-2.webp"

export const StyledHero = styled.section`
    position: relative;
    color: ${theme.colors.white};
    padding: 8rem 0 20rem;
    margin-bottom: 20rem;
    /* outline: 1px solid red; */
    
    ${mq(bp.small)} {
        padding: 9rem 0 23rem;
    }

    ${mq(bp.medium)} {
        padding-bottom: 25rem;
    }

    .decor {
        position: absolute;
        inset: 0;
        transform:  skewY(-12deg);
        z-index: -1;

        ${mq(bp.large)} {
            transform: skewY(-9deg);
        }

        .background-container,
        .stripes-container {
            ${flexColumn};
            justify-content: flex-end;
            align-items: center;
            position: absolute;
            ${size('100%')};
        }

        .grid,
        .grid-1 {
            display: grid;
            position: absolute;
            align-content: end;
            grid-template-rows: repeat(auto-fill, ${rem(64)});
            ${size('100%', '200%')};
        }
        
        .background-container {
            .grid {
                .background {
                    background-color: ${theme.colors.gray100};
                    grid-row: 1 / -2;
                    grid-column: 1 / -1;
                }
            }
        }
        
        .stripes-container {
            .grid-1 {
                height: 100%;
                grid-template-rows: repeat(auto-fill, ${rem(45)}); // tweak these values later
                grid-template-columns: repeat(auto-fill, minmax(${rem(30)}, 1fr));

                ${mq(bp.small)} {
                    grid-template-rows: repeat(auto-fill, ${rem(50)});
                    grid-template-columns: repeat(auto-fill, minmax(${rem(60)}, 1fr));
                }
                ${mq(bp.medium)} {
                    grid-template-rows: repeat(auto-fill, ${rem(64)});
                    grid-template-columns: repeat(auto-fill, minmax(${rem(80)}, 1fr));
                }
                ${mq(bp.large)} {
                    grid-template-columns: repeat(auto-fill, minmax(${rem(100)}, 1fr));
                }

                // starting stripe on the left side from the bottom
                .stripe:nth-child(1) {
                    grid-column:  1 / span 3; // for positive values, span comes after '/' and vice versa
                    grid-row: span 1 / -2;
                    background-color: ${theme.colors.accent5};

                    ${mq(bp.small)} {
                        grid-column: 1 / span 8;
                        grid-row: span 1 / -2;
                    }

                    ${mq(bp.xlarge)} {
                        grid-column: 3 / span 7;
                        grid-row: span 1 / -1;
                    }

                    ${mq(bp.xxlarge)} {
                        grid-column: 6 / span 11;
                    }
                }

                .stripe:nth-child(2) {
                    display: none;
                    background-color: ${theme.colors.accent2200};
                    
                    ${mq(bp.xlarge)} {
                        display: block;
                        grid-column: 2 / span 3;
                        grid-row: span 1 /-2;
                    }

                    ${mq(bp.xxlarge)} {
                        grid-column: 4 / span 5;
                    }
                }

                .stripe:nth-child(3) {
                    display: none;
                    background-color: ${theme.colors.accent5};
                    
                    ${mq(bp.xlarge)} {
                        display: block;
                        grid-column: 1 / span 2;
                        grid-row: span 1 / -3;
                    }

                    ${mq(bp.xxlarge)} {
                        grid-column: 1 / span 4;
                    }
                }

                // starting stripe on the right side from the bottom
                .stripe:nth-child(4) {
                    grid-column: span 3 / -1;
                    grid-row: span 1 / -3;
                    background-color:  ${theme.colors.accent2200};
                    
                    ${mq(bp.small)} {
                        grid-column: span 8 / -1;
                        grid-row: span 1 / -3;
                    }

                    ${mq(bp.xlarge)} {
                        grid-column: span 7 / -3;
                        grid-row: span 1 / -2;
                    }

                    ${mq(bp.xxlarge)} {
                        grid-column: span 11 / -6;
                    }
                }

                .stripe:nth-child(5) {
                    display: none;
                    background-color: ${theme.colors.accent5};

                    ${mq(bp.xlarge)} {
                        display: block;
                        grid-column: span 3 / -2;
                        grid-row: span 1 / -3;
                    }

                    ${mq(bp.xxlarge)} {
                        grid-column: span 5 / -4;
                    }
                }
                
                .stripe:nth-child(6) {
                    display: none;
                    background-color: ${theme.colors.accent2200};

                    ${mq(bp.xlarge)} {
                        display: block;
                        grid-column: span 2 / -1;
                        grid-row: span 1 / -4;
                    }

                    ${mq(bp.xxlarge)} {
                        grid-column: span 4 / -1;
                    }
                }
            }
        }
    }

    .decor-image-wrapper {
        position: absolute;
        border-radius: 10px;
        transform: skewY(-12deg);
        /* outline: 2px solid brown; */
        overflow: hidden;

        ${mq(bp.large)} {
            transform: skewY(-9deg);
        }

        .image {
            position: absolute;
            inset: -25%;
            transform: skewY(12deg);

            ${mq(bp.large)} {
                transform: skewY(9deg);
            }
        }

        // also acting as center image on small screens
        &.left {
            top: 62%;
            left: 50%;
            transform: translateX(-50%) skewY(-12deg);
            ${size('90%', '65%')};

            ${mq('34.375em')} { // For mobile phones over 550px in width (also includes landscape mode)
                ${size('80%')}
            }
            
            ${mq(bp.small)} {
                top: 58%;
                left: 50%;
                transform: translateX(calc(-100% - 0.8rem)) skewY(-12deg);
            }
            
            ${mq(bp.large)} {
                transform: translateX(calc(-100% - 0.8rem)) skewY(-9deg);
            }
            
            .image {
                background: url(${leftDecorImage}) center / cover no-repeat;

                ${mq('34.375em')} {
                    background-position: center top;
                }
            }
        }
        
        &.right {

            ${mq(bp.small)} {
                display: block;
                top: 65%;
                right: 50%;
                transform: translateX(calc(100% + 0.8rem)) skewY(-12deg);
            }
            
            ${mq(bp.large)} {
                transform: translateX(calc(100% + 0.8rem)) skewY(-9deg);
                /* outline: 1px solid brown; */
            }

            .image {
                background: url(${rightDecorImage}) center / cover no-repeat;
            }
        }

        &.left, &.right {
            ${mq(bp.small)} {
                ${size('30%', '55%')};
            }

            ${mq(bp.medium)} {
                ${size('25%', '60%')};
            }

            ${mq(bp.xlarge)} {
                ${size('20%', '65%')};
            }

            ${mq(bp.xxlarge)} {
                ${size('15%', '80%')};
            }
        }
    }

    .text-content {
        text-align: center;
        padding-inline: 1rem;

        ${mq(bp.small)} {
            padding-inline: 0;
        }

        .heading {
            max-width: 20ch;
            margin-inline: auto;
            font-size: clamp(2rem, 5vw, 3rem);
            font-weight: 500;
            color: ${theme.colors.primary700};
            line-height: 1.2em;
            
            ${mq(bp.small)} {
                line-height: 1em;
            }
        }
    
        .summary {
            color: ${theme.colors.primaryDesat400};
            font-weight: 300;
    
            ${mq(bp.small)} {
                max-width: 50ch;
                font-size: ${rem(16)};
            }
    
            .highlight {
                font-weight: 400;
            }
        }
    }
`