import { StyledAbout } from "./About.styled";
import { Container } from "styles/components/Container.styled";
import { theme } from "styles/Utilities.styled";
import { LinkAButton } from "components/LinkButton";
import {
  SectionHeading,
  SectionSubHeading,
  SectionSummary,
} from "styles/components/SectionText.styled";

export const About = () => {
  return (
    <StyledAbout>
      <Container>
        <div className="wrapper">
          <div className="text-content">
            <SectionSubHeading className="sub-heading">
              Who are we?
            </SectionSubHeading>
            <SectionHeading className="heading">
              Experts who exceed
            </SectionHeading>
            <SectionHeading className="heading">
              expectations for a living
            </SectionHeading>
            <SectionSummary className="summary">
              Polymorph Labs is a worldwide custom software development and
              consulting company, currently based in Accra, Ghana. We are
              committed to meeting the highest ethical standards by implementing
              world-class software solutions without compromising their quality
              or functionality.
            </SectionSummary>
            <LinkAButton
              className="about-cta"
              text="Meet The Team"
              to="/about"
              color={theme.colors.primary400}
              border_color={theme.colors.primary400}
            />
          </div>
          <div className="images-container">
            <div className="img-wrapper">
              <img
                src={require("assets/about/asset-1.jpg")}
                width={300}
                height={200}
                loading="lazy"
                alt="Woman and man behind computer"
              />
            </div>
            <div className="img-wrapper">
              <img
                src={require("assets/about/asset-2.jpg")}
                width={300}
                height={200}
                loading="lazy"
                alt="White desktop setup with laptop and digital camera overlooking window"
              />
            </div>
            <div className="img-wrapper">
              <img
                src={require("assets/about/asset-3.jpg")}
                width={300}
                height={200}
                loading="lazy"
                alt="Two people sitting on a white couch using macbooks"
              />
            </div>
          </div>
        </div>
      </Container>
    </StyledAbout>
  );
};
