import { useState } from "react";

import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { FiChevronRight, FiChevronLeft } from "react-icons/fi";

import { StyledCarousel } from "./Carousel.styled.js";
import { testimonials } from "data/testimonials";

const responsive = {
  0: { items: 1 },
  568: { items: 2 }, // a little inaccurate but it works fine
  768: { items: 3 },
};

const items = testimonials.map(({ name, image, company, text }, index) => {
  return (
    <div className="card" key={index}>
      <div className="card-header">
        <div className="card-img-wrapper">
          <img src={image} alt={name} />
        </div>
        <div className="card-header-text-content">
          <h4 className="card-name">{name}</h4>
          <p className="card-company">{company}</p>
        </div>
      </div>
      <p className="card-text">{text}</p>
    </div>
  );
});

export const Carousel = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const slidePrev = () =>
    setActiveIndex(prevState =>
      prevState <= 0 ? items.length - 1 : prevState - 1
    );
  const slideNext = () =>
    setActiveIndex(prevState =>
      prevState >= items.length - 1 ? 0 : prevState + 1
    );
  const syncActiveIndex = ({ item }) => setActiveIndex(item);

  return (
    <StyledCarousel>
      <AliceCarousel
        mouseTracking
        items={items}
        responsive={responsive}
        controlsStrategy="alternate"
        disableButtonsControls
        keyboardNavigation={true}
        activeIndex={activeIndex}
        onSlideChanged={syncActiveIndex}
        autoPlay
      />
      <button className="prev-btn" onClick={slidePrev}>
        <FiChevronLeft />
      </button>
      <button className="next-btn" onClick={slideNext}>
        <FiChevronRight />
      </button>
    </StyledCarousel>
  );
};
