import styled from "styled-components";
import { bp, mq, rem, size } from "styles/Utilities.styled";

export const StyledPartners = styled.section`
    margin-top: 5rem;

    .partners-container {
        display: flex;
        justify-content: space-between;
        gap: 2rem;
        flex-flow: row wrap;
        margin-top: 3rem;
        
        ${mq(bp.small)} {
            max-width: ${rem(670)};
            margin-inline: auto;
        }
    }

    .img-wrapper {
        display: grid;
        place-items: center;
        /* outline: 1px solid; */

        ${size(rem(120), 'auto')}
    }
`;