import { css } from "styled-components";

// Helper function to convert from px to rem (1rem = 16px by browser default)
export const rem = (value, omitUnit = false) => {
    return `${(value / 16)}${omitUnit === true ? '' : 'rem'}`;
}

// Helper function to add alpha values to rgb and hsl colors
export const alpha = (color, opacity = 1) => {
    const hex_pattern = /^#([A-Fa-f0-9]{6})$/
    const hsl_pattern = /hsl\(\s*([0-2]?[0-9]{1,2}|3[0-5][0-9]|360)\s*,\s*(0|[1-9][0-9]?|100)%\s*,\s*(0|[1-9][0-9]?|100)%\s*\)/ // chose to be explicit on the digits to prevent evaluating wrong digits
    const hsl_pattern_with_opacity = /hsla?\(\s*([0-2]?[0-9]{1,2}|3[0-5][0-9]|360)\s*,\s*(0|[1-9][0-9]?|100)%\s*,\s*(0|[1-9][0-9]?|100)%\s*,\s*0(\.\d+)?|1\s*\)/
    let newColor = '';

    if (opacity < 0 || opacity > 1) {
        throw new Error('Opacity must be between 0 and 1 inclusive');
    }
    const formatted_opacity = +String(opacity).padStart(3, 0);

    if (color.includes('#')) {
        if (!hex_pattern.test(color)) throw new Error('Invalid hexadecimal - Value provided must follow the 6-character syntax (#FFFFFF)')
        else return `${color}${Math.floor(formatted_opacity * 255).toString(16).padStart(2, 0)}`;
    }

    if (hsl_pattern.test(color)) {
        newColor = color.slice(0, color.length - 1) + `,${formatted_opacity})`;
    } else if (hsl_pattern_with_opacity.test(color)) {
        let temp = color.split(',');
        newColor = temp.reduce((acc, item, index) => {
            if (index === temp.length - 1) return `${acc},${String(opacity)})`
            if (index > 0) return acc + ',' + item
            else return acc + item;
        }, '')
    }

    // console.log(newColor, color);
    if (!newColor) throw new Error('Invalid color provided - only hex and hsl are supported') // ignore rgb - might as well make it hsl :)
    return newColor;
}

// Breakpoints object with values from mobile up
export const bp = {
    small: `${rem(768, true)}em`,
    medium: `${rem(1024, true)}em`,
    large: `${rem(1200, true)}em`,
    xlarge: `${rem(1440, true)}em`,
    xxlarge: `${rem(2100, true)}em`
}

export const theme = {
    colors: {
        primary100: 'hsl(221, 61%, 88%)',
        primary400: 'hsl(222, 72%, 47%)',
        primary600: 'hsl(236, 76%, 12%)',
        primary700: 'hsla(222, 72%, 15%, 0.9)',
        primaryDesat200: 'hsl(223, 17%, 60%)',
        primaryDesat300: 'hsl(223, 17%, 50%)',
        primaryDesat400: 'hsl(223, 17%, 34%)',
        primaryDesat500: 'hsl(222, 45%, 35%)',

        // Accents
        accent400: 'hsl(193, 100%, 63%)', // main accent
        accent500: 'hsl(193, 100%, 45%)', // main accent

        accent2: 'hsl(254, 79%, 48%)',
        accent2100: 'hsl(254, 73%, 77%)',
        accent2200: 'hsl(254, 78%, 70%)',
        accent2500: 'hsl(254, 62%, 27%)',

        accent3: 'hsl(32, 100%, 73%)',
        accent3500: 'hsl(32, 62%, 27%)',
        accent3600: 'hsl(32, 76%, 22%)',

        accent4: 'hsl(120, 100%, 73%)',
        accent4500: 'hsl(120, 62%,27%)',
        accent4600: 'hsl(120, 76%, 22%)',

        accent5: 'hsl(44, 96%, 70%)',

        // neutral
        white: 'hsl(0, 100%, 100%)',
        black: 'hsl(0,0%, 0%)',
        gray100: 'hsl(210, 50%, 98%)',
        gray300: 'hsl(210, 20%, 80%)',

        //social icons
        facebook: '#3b5998',
        twitter: '#00aced',
        instagram: 'linear-gradient(45deg, #fcb045 , #fd1d1d, #833ab4 90%);',
        linkedin: '#007bb6',

    },
    fonts: {
        primary: 'Manrope, Rubik, sans-serif', /*'Manrope', /*'Rubik, sans-serif',*/
    },
    ease: {
        // easing functions
        slowStart: 'cubic-bezier(0.52, 0.01, 0, 1)',
        quickStartAndEnd: 'cubic-bezier(0.19, 1, 0.22, 1)',
        almostLinear: 'cubic-bezier(.4,.6,.6,1)',

    }
}

/*********************************************************************
CSS UTILITIES (like Sass mixins)
**********************************************************************/
export const flexCenter = css`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const flexColumn = css`
    display: flex;
    flex-direction: column;
`;

export const flexSpBetween = css`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const absoluteCenter = css`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

export const pseudo = css`
    content: '';
    position: absolute;
`;

export const size = (width, height = width) => {
    return css`
        width: ${width};
        height: ${height};
    `;
}

export const mq = (breakpoint) => {
    return css`
        @media screen and (min-width: ${breakpoint})
    `;
}