import { Gallery } from "./sections/Gallery";
import { Hero } from "./sections/Hero";

export const Work = () => {
  return (
    <>
      <Hero />
      <Gallery />
    </>
  );
};
