import { Container } from "styles/components/Container.styled";
import { SectionHeading } from "styles/components/SectionText.styled";
import { StyledPrivacyPolicy } from "./PrivacyPolicy.styled";

export const PrivacyPolicy = () => {
  return (
    <StyledPrivacyPolicy>
      <Container>
        <div className="text-content">
          <SectionHeading className="heading">Privacy Policy</SectionHeading>
          <p>
            At Polymorph Labs Ghana Limited, we take your privacy very
            seriously. We are committed to protecting your personal information
            and using it only for the purposes for which it was intended. This
            privacy policy outlines the types of information we collect, how we
            use it, and your rights regarding your personal information.
          </p>

          <h3>Information we collect</h3>
          <p>
            We collect personal information that you provide to us when you use
            our services, such as your name, email address, phone number, and
            other contact information. We also collect information about your
            use of our services, including your IP address, device information,
            and other usage data.
          </p>

          <h3>How we use your information</h3>
          <p>
            We use your personal information to provide and improve our
            services, communicate with you, and fulfil our contractual
            obligations. We may also use your information to send you marketing
            communications, with your consent.
          </p>

          <h3>Information sharing and disclosure</h3>
          <p>
            We do not sell your personal information to third parties. However,
            we may share your information with our service providers, business
            partners, and other third-party vendors who assist us in providing
            our services. We may also disclose your information to law
            enforcement agencies or other third parties if we believe that such
            disclosure is necessary to comply with applicable laws, regulations,
            or legal processes.
          </p>

          <h3>Security</h3>
          <p>
            We take appropriate measures to protect your personal information
            from unauthorised access, disclosure, alteration, or destruction. We
            use industry-standard security measures, such as firewalls and
            encryption, to protect your data.
          </p>

          <h3>Your rights</h3>
          <p>
            You have the right to access, correct, update, and delete your
            personal information. You also have the right to object to the
            processing of your personal information, as well as the right to
            withdraw your consent to the processing of your personal
            information. To exercise any of these rights, please contact us
            using the contact information provided below.
          </p>

          <h3>Changes to this privacy policy</h3>
          <p>
            We may update this privacy policy from time to time. If we make any
            material changes to this policy, we will notify you by email or by
            posting a notice on our website.
          </p>

          <h3>Changes to this privacy policy</h3>
          <p>
            If you have any questions or concerns about our privacy policy or
            our use of your personal information, please contact us at
            admin@polymrophlabs.io
          </p>

          <p>Last updated: 24th February 2023</p>
        </div>
      </Container>
    </StyledPrivacyPolicy>
  );
};
