import styled from "styled-components";
import { bp, mq, rem, theme } from "styles/Utilities.styled";

export const StyledContact = styled.section`
    position: absolute;
    left: 50%;
    top: -9%;
    transform: translateX(-50%);
    width: min(85%, ${rem(550)});
    padding: 2rem 0;
    color: ${theme.colors.white};
    text-align: center;
    background-color: ${theme.colors.accent400};
    border-radius: 30px 0 30px 0px;
    /* border-radius: 10px; */
    z-index: 2;
    
    ${mq(bp.small)} {
        top: -12%;
        text-align: left;
        padding-inline: 1rem;
    }
    ${mq(bp.medium)} {
        width: min(85%, ${rem(600)});
        top: -13%;
    }
    
    ${mq(bp.large)} {
        top: -17%;
        width: min(85%, ${rem(700)});
    }
    
    .wrapper {
        ${mq(bp.small)} {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        
        ${mq(bp.medium)} {
            gap: 2rem;
        }

        ${mq(bp.large)} {
            gap: 7rem;
        }
    }

    .heading {
        max-width: 15ch;
        margin: 0 auto;
        font-size: clamp(1.8rem, 4vw, 2rem);
    }
    
    .text {
        margin-top: .5rem;
    }

    .contact-cta {
        padding-inline: 2em;
        margin-top: 1.5rem;
        font-weight: 500;

        ${mq(bp.small)} {
            margin-top: 0;
            padding-inline: 2.5em;
        }
    }
`;