import { StyledTechCard } from "./TechCard.styled";

export const TechCard = ({ text, logo, className }) => {
  return (
    <StyledTechCard className={className} tabIndex={0}>
      <div className="img-wrapper">
        <img src={logo} alt={text} />
      </div>
      <p className="text">
        <span>{text}</span>
      </p>
    </StyledTechCard>
  );
};
