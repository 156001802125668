export const testimonials = [
    {
        name: 'Kwabena  Yeboah',
        image: require('assets/testimonials/danielhardman.png'),
        company: 'Empomart',
        text: "Polymorph Labs is a competent partner in developing and supporting a network marketing application suite, with great attention to detail, consistency, and positive impact on business and customers."
    },
    {
        name: 'Payin Marfo ',
        image: require('assets/testimonials/jessicapearson.png'),
        company: 'Premier Solutions',
        text: "Premier chauffeur was initially seen as a quick fix to immediate problems, but after extensive discussions with Polymorph, it became a complete solution that adds value and improves service delivery."
    },
    {
        name: 'Jerry Sam',
        image: require('assets/testimonials/rachelzane.png'),
        company: 'PenPlusBytes',
        text: "When we reached out to Polymorph Labs, we were looking for an innovative solution with precise execution. That’s exactly what they delivered. They have been consistent with the delivery of features throughout the project. We look forward to working closely again soon!"
    },
    {
        name: 'Stanley Blankson Jr.',
        image: require('assets/testimonials/louislitt.png'),
        company: 'PAQ Technologies',
        text: "Polymorph Labs is an invaluable partner, attentive and responsive throughout the development of solutions. Together, we delivered a product that exceeded expectations, showcasing their professionalism and commitment to excellence. Highly recommended."
    },
    {
        name: 'Atsu Foli',
        image: require('assets/testimonials/harveyspecter.png'),
        company: 'GMES & Africa',
        text: "Polymorph Labs provided an excellent experience with a talented, knowledgeable, and dedicated team that delivered high-quality software solutions, completing the project on time and within budget. The outcome was satisfying, and we hope to work with them again."
    },
    {
        name: 'Timoty Kotin',
        image: require('assets/testimonials/katrinabennett.png'),
        company: 'Superfluid Labs',
        text: "Polymorph Labs brought our vision to life with exceptional solution engineering skills, remaining professional, communicative, and efficient throughout the process. Highly recommended for top-notch software engineering partnerships with satisfying end results."
    },
]