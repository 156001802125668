import styled from "styled-components";
import { alpha, bp, mq, pseudo, rem, size, theme } from "styles/Utilities.styled";

export const StyledGallery = styled.section`
    margin-top: 5rem;

    ${mq(bp.small)} {
        margin-top: 7rem;
    }

    .heading {
        font-size: clamp(2rem, 5vw, 3rem);
    }

    .gallery-grid {
        display: grid;
        grid-template-columns: 1fr;
        gap: 1rem;
        margin-top: 3rem;
        width: min('90%', ${rem(1100)});
        margin-inline: auto;
        
        ${mq(bp.small)} {
            grid-template-columns: repeat(3, 1fr);
            grid-auto-flow: row dense;
            width: min(90%, ${rem(1100)});
            margin-top: 4rem;
        }
        
        ${mq(bp.large)} {
            grid-template-columns: repeat(4, 1fr);
        }
        
        .img-wrapper {
            position: relative;
            border-radius: 10px;
            height: ${rem(290)};
            overflow: hidden;

            ${mq(bp.small)} {
                min-height: ${rem(320)};
            }

            ${mq(bp.medium)} {
                min-height: ${rem(430)};
            }

            ${mq(bp.large)} {
                min-height: ${rem(400)};
            }

            ${mq(bp.xlarge)} {
                min-height: ${rem(460)};
            }

            ${mq(bp.xxlarge)} {
                min-height: ${rem(830)};
            }

            &::before,
            &::after {
                ${pseudo};
                inset: 0;
                z-index: 1;
            }
            
            &::before {
                background: linear-gradient(rgba(0,0,0,.4), rgba(0,0,0,0) 30%)
            }
            
            &::after {
                transition: background .4s;
                background: rgba(0,0,0,0);
            }

            &:hover,
            &:focus {
                &::after {
                    background: ${alpha(theme.colors.primaryDesat500, .6)};
                }
                img {
                    transform: scale(1.2);
                }
            }

            img {
                ${size('100%')};
                transition: transform 1.5s ${theme.ease.almostLinear};
            }

            .text-content {
                position: absolute;
                top: 8%;
                left: 8%;
                color: ${theme.colors.white};
                z-index: 2;

                ${mq(bp.small)} {
                    left: 7%;
                }
                
                .title {
                    font-size: ${rem(24)};

                    ${mq(bp.small)} {
                        font-size: ${rem(28)};
                    }

                    ${mq(bp.xxlarge)} {
                        font-size: ${rem(32)};
                    }
                }
            }

            &.span-2 {
                ${mq(bp.small)} {
                    grid-column: span 2;
                }
            }
        }
    }
`;