import { useState } from "react";

import { Container } from "styles/components/Container.styled";
import {
  SectionHeading,
  SectionSubHeading,
  SectionSummary,
} from "styles/components/SectionText.styled";
import { StyledHistory } from "./History.styled";
import { AccentText } from "styles/components/AccentText.styled";
import { theme } from "styles/Utilities.styled";
import { Preloader } from "components/Preloader";

export const History = () => {
  const [isLoading, setIsLoading] = useState(true);

  return (
    <StyledHistory>
      {isLoading && <Preloader />}
      <Container>
        <div className="wrapper">
          <div className="text-content">
            <SectionSubHeading className="sub-heading">
              Established 2018
            </SectionSubHeading>
            <SectionHeading className="heading">
              We put <AccentText>our partners</AccentText> before profit
            </SectionHeading>
            <SectionSummary className="summary">
              Michael Soli and his co-founders don't do things halfway through.
              No. When they started Polymorph Labs Ghana in 2018, they committed
              themselves to complete what their political leaders often fail to
              achieve:{" "}
              <AccentText color={theme.colors.accent500}>
                believing in African talent
              </AccentText>
              . Betting on their own potential, they started a journey that
              would lead them to develop high-tech solutions, today their
              solutions span various fields. They are convinced of the relevance
              of African solutions, the power of education and the importance of
              believing in the human resource available in Ghana and Africa as a
              whole.
            </SectionSummary>
          </div>
          <div className="img-wrapper">
            <img
              src={require("assets/team/Agbo.webp")}
              alt="Michael Soli, Co-founder"
              title="Michael Agbo Tettey Soli, Co-founder"
            />
            {isLoading && (
              <img
                aria-hidden="true"
                src={require("assets/team/Soli.webp")}
                onLoad={() => setIsLoading(false)}
                className="false-image"
                alt=""
              />
            )}
            <span className="image-title">Michael Agbo Tettey Soli</span>
          </div>
        </div>
      </Container>
    </StyledHistory>
  );
};
