import { useState } from "react";

import { Container } from "styles/components/Container.styled";
import { StyledHero } from "./Hero.styled";
import { Preloader } from "components/Preloader";
import { SectionSummary } from "styles/components/SectionText.styled";

export const Hero = () => {
  const [isLoading, setIsLoading] = useState(true);
  return (
    <StyledHero>
      {isLoading && <Preloader />}
      <div aria-hidden="true" className="decor">
        <div className="background-container">
          <div className="grid">
            <div className="background"></div>
          </div>
        </div>
        <div className="stripes-container">
          <div className="grid-1">
            <div className="stripe"></div>
            <div className="stripe"></div>
            <div className="stripe"></div>
            <div className="stripe"></div>
          </div>
        </div>
      </div>
      <div className="decor-image-wrapper center">
        <div className="image"></div>
        {/* using CSS to place image in div for flexibility*/}
        {isLoading && (
          <img
            aria-hidden="true"
            src={require("assets/services/asset-3.webp")}
            onLoad={() => setIsLoading(false)}
            className="false-image"
            alt=""
          />
        )}
      </div>
      <div className="decor-image-wrapper left">
        <div className="image"></div>
      </div>
      <div className="decor-image-wrapper right">
        <div className="image"></div>
      </div>
      <Container>
        <div className="text-content">
          <h1 className="heading">
            Creativity and imagination are at the heart of everything we do
          </h1>
          <SectionSummary className="summary">
            Polymorph Labs offers a variety of services you can choose from. We
            make crazy ideas come to life and build products that simply can't
            be ignored.
          </SectionSummary>
        </div>
      </Container>
    </StyledHero>
  );
};
