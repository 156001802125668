import styled from "styled-components"
import { alpha, bp, flexColumn, flexSpBetween, mq, pseudo, rem, size, theme } from "styles/Utilities.styled";

export const StyledStrategy = styled.section`
    padding-top: 8rem;
    /* outline: 1px solid red; */

    @media screen and (min-width: 23.4375em) { // 375px mobile
        padding-top: 10rem;
    }

    @media screen and (min-width: 25em) { // 400px mobile
        padding-top: 14rem;
    }
    
    ${mq(bp.small)} {
        padding-top: 18rem;
    }

    ${mq(bp.large)} {
        padding-top: 17rem;
    }

    .steps-container {
        ${flexColumn};
        gap: 3.5rem;
        margin-top: 3rem;
        /* outline: 1px solid red; */
        
        ${mq(bp.small)} {
            flex-flow: row wrap;
            gap: 2rem;
        }

        ${mq(bp.medium)} {
            padding-bottom: 6rem; // to cater for the steps that have been intentionally moved from their original positions with margins
        }
    }

    .step {
        
        ${mq(bp.small)}{
            position: relative;
            padding: 2rem 1.5rem;
            width: min(calc(50% - 2rem), 350px);
            border: 1px solid;
            overflow: hidden;
            
            &::before,
            &::after {
                ${pseudo};
                z-index: -1;
            }

            &::before {
                left: 50%;
                bottom: -3%;
                ${size('50%', rem(55))};
                transform: skewY(-30deg);
                transition: right .4s, transform .4s;
            }

            &::after {
                bottom: 0;
                right: 0;
                opacity: 0;
                ${size(rem(50))};
                border-radius: 50%;
                transition: opacity .4s, transform .4s;
            }

            &:hover,
            &:focus {
                &::before {
                    right: 0;
                    transform: skewY(-30deg) translate(100%);
                }

                &::after {
                    opacity: 1;
                    transform: scale(7);
                    transition-delay: .35s;
                }
            }
        }
  
        ${mq(bp.medium)} {
            width: min(calc(33% - 2rem), 350px);
        }


        &-counter {
            color: ${theme.colors.primary400};
            font-weight: 500;

            ${mq(bp.medium)} {
                font-size: 1rem;
            }
        }

        &-title-wrapper {
            ${flexSpBetween};
            padding-right: 1.5rem;

            ${mq(bp.small)} {
                padding-right: 0;
            }
        }

        &-title {
            font-size: clamp(${rem(25)}, 4vw, ${rem(30)});
            font-weight: 500;
            color: ${theme.colors.primary700};

            ${mq(bp.medium)} {
                font-size: ${rem(25)};
            }

            ${mq(bp.large)} {
                font-size: clamp(${rem(27)}, 4vw, ${rem(30)});
            }
        }

        &-icon {
            ${size(rem(55))};
            fill: ${theme.colors.primary400}
        }

        &-body {
            margin-top: 1rem;
            z-index: 2;
            color: ${theme.colors.primaryDesat300};
        }
    }

    .step:nth-child(1) {
        ${mq(bp.small)} {
            border-color: rgba(0, 0, 0, 0) ${alpha(theme.colors.primary700, 0.2)} ${alpha(theme.colors.primary700, 0.2)}
            rgba(0, 0, 0, 0);
            border-radius: 0 0 10px 0;

            &::before,
            &::after {
                background: ${alpha(theme.colors.primary400, .4)};
            }
        }
    }

    .step:nth-child(2) {
        ${mq(bp.small)} {
            border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) ${alpha(theme.colors.primary700, 0.2)}
            ${alpha(theme.colors.primary700, 0.2)};
            border-radius: 0 0 0 10px;

            &::before,
            &::after {
                background: ${alpha(theme.colors.accent5, .4)};
            }
        }
        
        ${mq(bp.medium)} {
            margin: 3rem 0 -3rem;
            border-color: rgba(0,0,0,0) rgba(30,30,30,.2) rgba(30,30,30,.2) rgba(30,30,30,.2);
            border-radius: 0 0 10px 10px;
        }
    }

    .step:nth-child(3) {
        ${mq(bp.small)} {
            border-color: ${alpha(theme.colors.primary700, 0.2)} ${alpha(theme.colors.primary700, 0.2)}
            ${alpha(theme.colors.primary700, 0.2)} rgba(0, 0, 0, 0);
            border-radius: 0 10px 10px 0;

            &::before,
            &::after {
                background: ${alpha(theme.colors.accent400, .3)};
            }
        }
        
        ${mq(bp.medium)} {
            margin: 6rem 0 -6rem;
            border-color: rgba(0,0,0,0) rgba(0,0,0,0) rgba(30,30,30,.2) rgba(30,30,30,.2);
            border-radius: 0 0 0 10px;
        }
    }

    .step:nth-child(4) {
        
        ${mq(bp.small)} {
            border-color: ${alpha(theme.colors.primary700, 0.2)} rgba(0, 0, 0, 0) ${alpha(theme.colors.primary700, 0.2)}
            ${alpha(theme.colors.primary700, 0.2)};
            border-radius: 10px 0 0 10px;

            &::before,
            &::after {
                background: ${alpha(theme.colors.accent2, .3)};
            }
        }
        
        ${mq(bp.medium)} {
            border-color: rgba(30,30,30,.2) rgba(30,30,30,.2) rgba(0,0,0,0) rgba(0,0,0,0);
            border-radius: 0 10px 0 0;
        }
    }
    
    .step:nth-child(5) {
        
        ${mq(bp.small)} {
            border-color: ${alpha(theme.colors.primary700, 0.2)} ${alpha(theme.colors.primary700, 0.2)} rgba(0, 0, 0, 0)
            rgba(0, 0, 0, 0);
            border-radius: 0 10px 0 0;

            &::before,
            &::after {
                background: ${alpha(theme.colors.primary700, .4)};
            }
        }
        
        ${mq(bp.medium)} {
            margin: 3rem 0 -3rem;
            border-color: rgba(30,30,30,.2) rgba(30,30,30,.2) rgba(0,0,0,0) rgba(30,30,30,.2);
            border-radius: 10px 10px 0 0;
        }
    }

    .step:nth-child(6) {
        ${mq(bp.small)} {
            border-color: rgba(30,30,30,.2) rgba(0,0,0,0) rgba(0,0,0,0) rgba(30,30,30,.2);
            border-radius: 10px 0 0 0;

            &::before,
            &::after {
                background: ${alpha(theme.colors.accent4, .4)};
            }
        }
        
        ${mq(bp.medium)} {
            margin: 6rem 0 -6rem;
        }
    }
`;