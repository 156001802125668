import styled from "styled-components";
import { theme, rem } from "../Utilities.styled";

export const SectionHeading = styled.h2`
    margin-top: 1rem;
    color: ${theme.colors.primary700};
    font-size: clamp(1.7rem, 4vw, 2.2rem);
    line-height: 1.2em;
    text-align: center;
`;

export const SectionSubHeading = styled.h3`
    color: ${theme.colors.primary400};
    font-size: ${rem(16)};
    font-weight: 500;
    text-align: center;
`;

export const SectionSummary = styled.p`
    margin: 1.5rem auto 0;
    color: ${theme.colors.primaryDesat300};
    font-size: ${rem(15.5)};
    max-width: 45ch;
    text-align: center;
`;