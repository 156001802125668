import styled from "styled-components";
import { rem, size, mq, bp, flexSpBetween } from "styles/Utilities.styled";

export const StyledFindUs = styled.section`

    ${mq(bp.xlarge)} {
        padding-top: 4rem;
    }

    ${mq(bp.xxlarge)} {
        padding-top: 10rem;
    }

    .wrapper {
        width: 100%;
        
        ${mq(bp.small)} {
            ${flexSpBetween};
            gap: 2rem;
            /* outline: 1px solid brown; */
        }

        ${mq(bp.medium)} {
            gap: 3.5rem;
        }

        ${mq(bp.large)} {
            gap: 5rem;
        }
    }

    .text-content {
        
        ${mq(bp.medium)} {
            align-self: start;
        }
        
        .heading, .sub-heading {
            text-align: left;
            ${mq(bp.medium)} {
                text-align: left;
            }
        }
        
        .info {
            /* text-align: center; */
            margin-top: 3rem;

            ${mq(bp.medium)} {
                margin-top: 4rem;
            }

            p {
                font-weight: 300;
            }
        }

        .small-heading {
            margin-top: 1.5rem;
            font-weight: 500;
            font-size: ${rem(19)};
        }
    }

    .iframe-wrapper {
        margin-top: 2rem;

        ${mq(bp.small)} {
            margin-top: 0;
            flex-grow: 1;
        }

        iframe {
            ${size('100%', '25rem')};

            ${mq(bp.medium)} {
                ${size('100%', '30rem')}
            }
        }
    }
`