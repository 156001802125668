import styled, { css, keyframes } from "styled-components";
import { Link } from "react-router-dom";
import { theme, rem, mq, bp, pseudo, size, alpha } from "styles/Utilities.styled";

const shared = css`
    padding: .6em 1.5em;
    font-size: ${rem(15)};
    border-radius: 50px;
    color: ${({ color }) => color || theme.colors.primary600};
    background-color: ${({ bg }) => bg || 'transparent'};
    border: ${({ border_color }) => `1px solid  ${border_color}` || 'none'};
    white-space: nowrap;

    ${mq(bp.small)} {
        padding: .7em 2em;
    }

    ${mq(bp.large)} {
        padding-block: .8em;
    }
`;

const arrowAnimation = keyframes`
  0% {opacity: 1; transform: translateY(-50%) scale(1); right: 10%;}
  25%{opacity: 0; transform: translateY(-50%) scale(0.9); right: 0;}
  26%{opacity: 0; transform: translateY(-50%) scale(0.9); right: 90%;}
  100% {opacity: 1; transform: translateY(-50%) scale(1); right: 80%;}
`;

// Styled Link Button - could be either primary or secondary
export const StyledLinkButton = styled(Link)`
    ${shared};
    position: relative;
    transition: color .3s, box-shadow .3s;
    z-index: 1; // to create a new stacking context
    overflow: hidden;

    &::before {
        ${pseudo};
        top: 0;
        left: 0;
        ${size('100%')};
        background-color: ${({ hover_bg }) => hover_bg || theme.colors.accent500};
        transform: scaleX(0);
        transform-origin: right;
        transition: transform .3s;
        z-index: -1;
    }

    &:hover {
        color: ${theme.colors.white};
        box-shadow: 0 5px 7px ${alpha(theme.colors.primary400, .4)};

        &::before {
            transform: scaleX(1);
            transform-origin: left;
        }
    }
    
    ${mq(bp.small)} {
        padding-block: .8em; // looks a bit tinier than the buttons with arrows for some reason
    }
    
    ${mq(bp.large)} {
        padding-block: .9em;
    }
`;

// Styled Link Button with Arrow - could be either primary or secondary
export const StyledLinkAButton = styled(Link)`
    ${shared};
    position: relative;
    padding-right: 2.7em;
    transition: background-color .3s;
    
    ${mq(bp.small)} {
        padding-right: 2.9em;
    }
    
    span {
        display: inline-block;
        transition: transform .3s;
    }
    
    svg {
        position: absolute;
        top: 50%;
        right: 10%;
        transform: translateY(-50%);
    }
    
    &:hover {
        background-color: ${alpha(theme.colors.primary400, .1)};
        
        span {
            transform: translateX(15px);
        }
        
        svg {
            animation: ${arrowAnimation} .8s forwards;
        }
    }
`;

// Styled Link Tertiary Button with Arrow
export const StyledLinkTAButton = styled(StyledLinkAButton)`
    padding-right: 1.9em;
    border-radius: 0;
    
    span {
        transition: transform .3s, color .3s;
    }
    
    svg {
        right: 0;
        transition: transform .3s .2s, color .3s .2s;
    }
    
    &:hover {
        background: none;

        span {
            color: ${theme.colors.primary600};
        }
        
        svg {
            animation: none;
            transform: translate(10px, -50%);
            color: ${theme.colors.primary600};
        }
    }
`;