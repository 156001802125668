import { HiOutlineLightBulb } from "react-icons/hi";
import { IoLeafOutline } from "react-icons/io5";
import { FaRegHandshake } from "react-icons/fa";
import { BsLightningCharge } from "react-icons/bs";

import { CircularCard } from "components/CircularCard";
import { Container } from "styles/components/Container.styled";
import {
  SectionHeading,
  SectionSubHeading,
  SectionSummary,
} from "styles/components/SectionText.styled";
import { alpha, theme } from "styles/Utilities.styled";
import { StyledPhilosophy } from "./Philosophy.styled";

export const Philosophy = () => {
  return (
    <StyledPhilosophy>
      <Container>
        <div className="wrapper">
          <div className="text-content">
            <SectionSubHeading>Our Philosophy</SectionSubHeading>
            <SectionHeading>What we believe</SectionHeading>
            <SectionSummary className="summary">
              We pride ourselves on being unique. With an original take on doing
              business which doesn't conform to the norm. What we are is the
              FUTURE!
            </SectionSummary>
          </div>

          <div className="cards-container">
            <CircularCard
              icon={HiOutlineLightBulb}
              overrideLightColorAlpha={true}
              lightColor={alpha(theme.colors.accent400, 0.15)}
              deepColor={theme.colors.accent500}
              cardText={
                "New is good. But, we're only interested if it's also better and smarter than what it's replacing. We love finding new ways of helping you solve problems."
              }
              heading={"Innovation"}
              bannerSubHeading={"We never run out of ideas"}
              textContentSubHeading={"An abundance of creativity"}
            />
            <CircularCard
              icon={IoLeafOutline}
              lightColor={theme.colors.accent4}
              deepColor={theme.colors.accent4500}
              cardText={
                "We keep things clear and understandable. So every day, you can see we're working in your best interests and you know exactly where you stand."
              }
              heading={"Simplicity"}
              bannerSubHeading={"Clear. Concise. Understandable."}
              textContentSubHeading={"We keep things stupid simple"}
            />
            <CircularCard
              icon={FaRegHandshake}
              lightColor={theme.colors.accent3}
              deepColor={theme.colors.accent3500}
              cardText={
                "We don't just get where you're coming from. We take time to understand where you want to get to as well. The more we know about you, the more we can help."
              }
              heading={"Empathy"}
              bannerSubHeading={"We want to understand you"}
              textContentSubHeading={"We want to really help"}
            />
            <CircularCard
              icon={BsLightningCharge}
              lightColor={theme.colors.accent2100}
              deepColor={alpha(theme.colors.accent2, 0.9)}
              cardText={
                "The digital world never stands still, neither do we. We're always thinking on our feet – agile and adaptable – to always be several steps ahead of the competition. It's in our name!"
              }
              heading={"Dynamic"}
              bannerSubHeading={"Improvise. Adapt. Overcome."}
              textContentSubHeading={"Polymorphing is our thing"}
            />
          </div>
        </div>
      </Container>
    </StyledPhilosophy>
  );
};
