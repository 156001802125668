import { useState } from "react";

import { StyledHero } from "./Hero.styled";
import { Container } from "styles/components/Container.styled";
import { LinkButton, LinkAButton } from "components/LinkButton";
import { bp, theme } from "styles/Utilities.styled";
import { AccentText } from "styles/components/AccentText.styled";
import { Preloader } from "components/Preloader";
import { isMatchMediaMinWidth } from "utils/matchMedia";

export const Hero = () => {
  const [isLoading, setIsLoading] = useState(true);

  return (
    <StyledHero>
      {isLoading && isMatchMediaMinWidth(bp.small) && <Preloader />}
      <div aria-hidden="true" className="decor">
        <div className="background-container">
          <div className="grid">
            <div className="background"></div>
            {isMatchMediaMinWidth(bp.small) && (
              <img
                aria-hidden="true"
                src={require("assets/hero-l.webp")}
                onLoad={() => setIsLoading(false)}
                className="false-image"
                alt=""
              />
            )}
          </div>
        </div>
        <div className="stripes-container">
          <div className="grid">
            <div className="stripe"></div>
            <div className="stripe"></div>
            <div className="stripe"></div>
            <div className="stripe"></div>
            <div className="stripe"></div>
            <div className="stripe"></div>
            <div className="stripe"></div>
          </div>
        </div>
      </div>
      <Container>
        <div className="text-content">
          <span className="greeting">Welcome to Polymorph Labs!</span>
          <h1>You asked for</h1>
          <h1>
            <AccentText>the best.</AccentText>
          </h1>
          <h1>Here we are.</h1>
          <p>We offer remarkable software solutions for your business needs.</p>

          <div className="btn-container">
            <LinkButton text="Know More" to="#" bg={theme.colors.white} />
            <LinkAButton
              text="Our Work"
              to="/work"
              color={theme.colors.white}
              border_color={theme.colors.white}
              style={{ fontWeight: 300 }}
            />
          </div>
        </div>
      </Container>
    </StyledHero>
  );
};
