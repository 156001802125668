import styled from "styled-components";
import { bp, mq } from "styles/Utilities.styled";

export const StyledPrivacyPolicy = styled.section`
    margin-top: 8rem;
    
    ${mq(bp.small)} {
        margin-top: 12rem;
    }

    .heading {
        text-align: left;
        font-weight: 700;
    }

    .text-content {
        max-width: 55ch;
        margin-inline: auto;
        
        ${mq(bp.medium)} {
            max-width: 60ch;
        }

        p:nth-child(2) {
            margin-top: 1.5rem;
        }
        
        h3 {
            margin-top: 2rem;

        &+p {
            margin-top: 0.75rem;
        }
    }
    
    p:last-child {
        margin-top: 3rem;
    }
}
`