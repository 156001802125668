import styled from "styled-components";
import { alpha, bp, mq, theme } from "styles/Utilities.styled";

export const StyledTestimonials = styled.section`
    position: relative;
    margin-top: 7rem;

    .quote-left,
    .quote-right {
        position: absolute;
        font-size: 3rem;
        color: ${alpha(theme.colors.primaryDesat400, .1)};

        ${mq(bp.small)} {
            font-size: 4rem;
        }
    }
    
    .quote-left {
        top: 13%;
        left: 5%;

        ${mq(bp.small)} {
            top: 10%;
        }
        ${mq(bp.medium)} {
            left: 8%;
        }
    }
    
    .quote-right {
        right: 5%;
        bottom: 0%;
        
        ${mq(bp.small)} {
            bottom: -3%;
        }
        ${mq(bp.medium)} {
            right: 8%;
        }
    }

`;