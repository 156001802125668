import styled from "styled-components";
import { alpha, rem, size, theme, flexCenter, bp, mq } from "styles/Utilities.styled";

export const StyledCarousel = styled.div`
    position: relative;
    margin-top: 2rem;
    
    .card {
        padding: 1.5rem;
        margin-inline: .5rem;
        border: 2px solid ${alpha(theme.colors.primary700, .07)};
        border-radius: 10px;

        ${mq(bp.small)} {
            min-height: ${rem(240)};
        }

        &-header {
            display: flex;
            gap: 1rem;

            &-text-content {
                flex-direction: column;
                justify-content: space-between;
            }
        }

        &-img-wrapper {
            ${size(rem(50))};
            border-radius: 50%;
            overflow: hidden;
        }

        &-name {
            font-weight: 600;
            font-size: ${rem(17)};
        }

        &-company {
            font-size: ${rem(15)};
        }

        &-text {
            margin-top: 1.5rem;
            font-size: ${rem(16)};
            font-weight: 300;
            color: ${theme.colors.primaryDesat300};
        }
    }

    .prev-btn,
    .next-btn {
        ${flexCenter};
        position: absolute;
        top: 40%;
        transform: translateY(-40%);
        ${size(rem(35))};
        border-radius: 50%;
        cursor: pointer;

        &:hover {
            filter: brightness(85%);
        }

        svg {
            font-size: 1.3rem;
            stroke: ${theme.colors.primaryDesat400};
        }
    }

    .prev-btn {
        left: -3%;

        ${mq(bp.small)} {
            left: -1.3%;
        }
        ${mq(bp.large)} {
            left: -0.8%;
        }
    }
    .next-btn {
        right: -3%;
        
        ${mq(bp.small)} {
            right: -1.3%;
        }
        ${mq(bp.large)} {
            right: -0.8%;
        }
    }
`;