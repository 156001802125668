import styled from "styled-components";
import { rem, theme, size, mq, bp, alpha, flexColumn } from "styles/Utilities.styled";

export const StyledHero = styled.section`
    position: relative;
    color: ${theme.colors.white};
    padding: 7rem 0;
    /* outline: 1px solid red; */
    
    ${mq(bp.small)} {
        padding: 9rem 0;
    }

    .decor {
        position: absolute;
        inset: 0;
        transform:  skewY(-12deg);
        z-index: -1;

        ${mq(bp.large)} {
            transform: skewY(-9deg);
        }

        .background-container,
        .stripes-container {
            ${flexColumn};
            justify-content: flex-end;
            align-items: center;
            position: absolute;
            ${size('100%')};
        }

        .grid,
        .grid-1,
        .grid-2 {
            display: grid;
            position: absolute;
            align-content: end;
            grid-template-rows: repeat(auto-fill, ${rem(64)});
            ${size('100%', '200%')};
        }
        
        .background-container {
            
            .grid {
                
                .background {
                    background-color: ${theme.colors.gray100};
                    grid-row: 1 / -2;
                    grid-column: 1 / -1;
                }
            }
        }
        
        .stripes-container {

            .grid-1,
            .grid-2 {
                height: 100%;
                grid-template-rows: repeat(auto-fill, ${rem(45)}); // tweak these values later
                grid-template-columns: repeat(auto-fill, minmax(${rem(30)}, 1fr));

                ${mq(bp.small)} {
                    grid-template-rows: repeat(auto-fill, ${rem(50)});
                    grid-template-columns: repeat(auto-fill, minmax(${rem(60)}, 1fr));
                }
                ${mq(bp.medium)} {
                    grid-template-rows: repeat(auto-fill, ${rem(64)});
                    grid-template-columns: repeat(auto-fill, minmax(${rem(80)}, 1fr));
                }
                ${mq(bp.large)} {
                    grid-template-columns: repeat(auto-fill, minmax(${rem(100)}, 1fr));
                }

                .stripe:nth-child(1) {
                    grid-column:  1 / span 2; // for positive values, span comes after '/' and vice versa
                    grid-row: span 1 / -2;
                    background-color: ${alpha(theme.colors.accent2, .9)};

                    ${mq(bp.xxlarge)} {
                        grid-column: 6 / span 3;
                    }
                }

                .stripe:nth-child(2) {
                    grid-column: 3 / span 2;
                    grid-row: span 1 /-2;
                    background-color: ${theme.colors.accent400};
                    
                    ${mq(bp.small)} {
                        grid-column: 3 / span 2;
                    }

                    ${mq(bp.xlarge)} {
                        grid-column: 3 / span 3;
                    }

                    ${mq(bp.xxlarge)} {
                        grid-column: 9 / span 2;
                    }
                }

                .stripe:nth-child(3) {
                    grid-column: 1 /span 1;
                    grid-row: span 1 / -9;
                    box-shadow: inset -0.5px 0 0 1.5px ${alpha(theme.colors.primary400, .2)};

                    ${mq(bp.xxlarge)} {
                        grid-column: 5 / span 1;
                    }
                }

                .stripe:nth-child(4) {
                    grid-column: span 1 / -1;
                    grid-row: span 1 / -9;
                    box-shadow: inset 0.5px 0 0 1.5px ${alpha(theme.colors.accent2, .2)};
                    
                    ${mq(bp.small)} {
                        grid-column: span 1 / -2;
                        grid-row: span 1 / -5;
                    }

                    ${mq(bp.xxlarge)} {
                        grid-column: span 1 / -6;
                    }
                }

                .stripe:nth-child(5) {
                    opacity: 0;

                    ${mq(bp.small)} {
                        opacity: 1;
                        grid-column: 1 / span 1;
                        grid-row: span 1 / -4;
                        background-color: ${alpha(theme.colors.primary700, .8)};
                    }

                    ${mq(bp.xxlarge)} {
                        grid-column: 8 / span 1;
                    }
                }

                .stripe:nth-child(6) {
                    grid-column: 1 / span 1;
                    grid-row: span 1 / -3;
                    background-color:  ${alpha(theme.colors.primaryDesat300, .6)};
                    
                    ${mq(bp.small)} {
                        grid-column: 1 / span 3;
                    }

                    ${mq(bp.xxlarge)} {
                        grid-column: 7 /span 3;
                    }
                }

                .stripe:nth-child(7) {
                    grid-column: span 1 / -1;
                    grid-row: span 1 / -2;
                    background-color: ${theme.colors.accent3};

                    ${mq(bp.xxlarge)} {
                        grid-column: span 1 / -8;
                    }
                }

                .stripe:nth-child(8) {
                    grid-column: span 3 / -1;
                    grid-row: span 1 / -1;
                    background-color:  ${alpha(theme.colors.primary700, .8)};

                    ${mq(bp.xxlarge)} {
                        grid-column: span 3 / -7;
                    }
                }

                .stripe:nth-child(9) {
                    opacity: 0;
                    
                    ${mq(bp.small)} {
                        opacity: 1;
                        grid-column: 2 /span 1;
                        grid-row: span 1 / -8;
                        background-color:  ${alpha(theme.colors.primary400, .4)};
                    }

                    ${mq(bp.xxlarge)} {
                        grid-column: span 1 / 7;
                    }
                }

                .stripe:nth-child(10) {
                    opacity: 0;
                    
                    ${mq(bp.small)} {
                        opacity: 1;
                        grid-column: span 1 / -1;
                        grid-row: span 1 / -6;
                        background-color:  ${alpha(theme.colors.accent2, .4)};
                    }

                    ${mq(bp.xxlarge)} {
                        grid-column: span 1 / -5;
                    }
                }
            }

            .grid-2 {
                right: 0;
                top: 94.5%;
                ${size('60%', '20%')};

                ${mq(bp.small)} {
                    top: 98.3%;
                }

                ${mq(bp.medium)} {
                    top: 91.1%;
                }

                .stripe:nth-child(1) {
                    opacity: 0;
                    
                    ${mq(bp.small)} {
                        opacity: 1;
                        grid-column: span 2 / -1;
                        grid-row: 1 / span 1;
                        background-color: ${theme.colors.accent500};
                    }

                    ${mq(bp.xxlarge)} {
                        grid-column: span 3 / -6;
                    }
                }

                .stripe:nth-child(2) {
                    opacity: 0;
                    
                    ${mq(bp.small)} {
                        opacity: 1;
                        grid-column: span 2 / -3;
                        grid-row: 1 / span 1;
                        background-color: ${alpha(theme.colors.primary600, .3)};
                    }

                    ${mq(bp.xlarge)} {
                        grid-column: span 3 / -3;
                    }

                    ${mq(bp.xxlarge)} {
                        grid-column: span 2 / -9;
                    }
                }
            }
        }
    }
    
    .text-content {
        text-align: center;
        padding-inline: 1rem;

        ${mq(bp.small)} {
            padding-inline: 0;
        }
        
        .heading {
            max-width: 20ch;
            margin-inline: auto;
            font-size: clamp(2rem, 5vw, 3rem);
            font-weight: 500;
            color: ${theme.colors.primary700};
            line-height: 1.2em;
            
            ${mq(bp.small)} {
                line-height: 1em;
            }
        }
        
        .summary {
            color: ${theme.colors.primaryDesat400};
            font-weight: 300;

            ${mq(bp.small)} {
                max-width: 50ch;
                font-size: ${rem(16)};
            }
        }
    }
`;
