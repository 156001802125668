import styled from "styled-components";
import { bp, mq, rem, theme } from "styles/Utilities.styled";

export const StyledTechStack = styled.section`
    margin-top: 5rem;
    padding: 5rem 0;
    background-color: ${theme.colors.gray100};

    .tech-stack-container {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        gap: 1rem;
        margin-top: 3rem;

        ${mq(bp.small)} {
            max-width: ${rem(670)};
            margin-inline: auto;
        }
    }
`;