export const teamMembers = [
    {
        id: 1,
        img: require('assets/team/Soli.webp'), // dimensions of image should be 1000x1500 with 'contain' as 'fit method' in order to appear properly
        name: 'Michael Agbo Tettey Soli',
        role: 'Engineering Manager',
    },
    // {
    //     id: 2,
    //     img: require('assets/team/Jamal.jpg'),
    //     name: 'Dr. Jamal-Deen Abdulai',
    //     role: 'Principal Solutions Architect',
    // },
    {
        id: 3,
        img: require('assets/team/Vera.jpg'),
        name: 'Prof. Vera Fiador',
        role: 'Senior Manager, Admin & Finance',
    },
    {
        id: 4,
        img: require('assets/team/declan.jpg'),
        name: 'Enoch Nyankah Mensah',
        role: 'Head of Engineering',
    },
    {
        id: 5,
        img: require('assets/team/melody.png'),
        name: 'Melody Nunya Kakrabah',
        role: 'Head of Operations',
    },
    {
        id: 6,
        img: require('assets/team/Stanley.jpg'),
        name: 'Stanley Blankson',
        role: 'Manager, Technology & Innovation',
    },
    {
        id: 8,
        img: require('assets/team/inkoom.jpg'),
        name: 'Michael Inkoom',
        role: 'Project Manager',
    },
    {
        id: 9,
        img: require('assets/team/Solomon.jpg'),
        name: 'Solomon Bekoe',
        role: 'Business Development Manager',
    },
    // {
    // id: 10,
    // img: require('assets/team/asset-11.jpg'),
    // name: 'Mushud Kofi Abu',
    // role: 'Mobile Apps Engineer, Team Lead',
    // },
    {
        id: 11,
        img: require('assets/team/elvis.jpg'),
        name: 'Elvis Agbesi',
        role: 'Software Engineer',
    },
    {
        id: 12,
        img: require('assets/team/kingsley.jpg'),
        name: 'Kingsley David Gyekye',
        role: 'Software Engineer',
    },
    {
        id: 13,
        img: require('assets/team/sylvester.jpg'),
        name: 'Sylvester Abiah',
        role: 'Software Engineer',
    },
    {
        id: 14,
        img: require('assets/team/pearl.jpg'),
        name: 'Pearl Kakraba Owusu',
        role: 'Manager, Admin & Finance',
    },
    {
        id: 15,
        img: require('assets/team/boakye.jpg'),
        name: 'Kofi Bawuah Boakye',
        role: 'Product Manager',
    },
    {
        id: 16,
        img: require('assets/team/reagan.jpg'),
        name: 'Reagan Marfo',
        role: 'UI/UX Engineer',
    },
    {
        id: 17,
        img: require('assets/team/isaac.jpg'),
        name: 'Isaac Osei Anane',
        role: 'UI/UX Engineer',
    },
    {
        id: 18,
        img: require('assets/team/kbf.jpg'),
        name: 'Kwaku Boachie Fosu',
        role: 'Software Engineer',
    },
    // {
    //     id: 20,
    //     img: require('assets/team/prince.jpg'),
    //     name: 'Prince Safo',
    //     role: 'Software Engineer',
    // },
    {
        id: 21,
        img: require('assets/team/medford.jpg'),
        name: 'Medford Kofi Kanyi',
        role: 'Head of Security & Infrastructure',
    },
    // {
    //     id: 22,
    //     img: require('assets/team/baiden.jpg'),
    //     name: 'Kelvin Ato Baiden',
    //     role: 'Software Engineer',
    // },
    // {
    //     id: 23,
    //     img: require('assets/team/patience.jpg'),
    //     name: 'Patience Lartey Quarshie',
    //     role: 'Q/A Officer',
    // },
    // {
    //     id: 24,
    //     img: require('assets/team/omar.jpg'),
    //     name: 'Omar Sinkari',
    //     role: 'Data Analyst',
    // },
    {
        id: 25,
        img: require('assets/team/paul.jpg'),
        name: 'Paul Nii Takie Ammah',
        role: 'Senior Software Engineer',
    },
    {
        id: 26,
        img: require('assets/team/george.jpg'),
        name: 'George Kwame Agyemang',
        role: 'Site Reliability Engineer',
    },
    {
        id: 27,
        img: require('assets/team/benjamin.jpg'),
        name: 'Benjamin Mba',
        role: 'Senior Software Engineer',
    },
]