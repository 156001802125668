import styled from "styled-components";

import { rem, theme, size, mq, bp, alpha, flexColumn } from "styles/Utilities.styled";
import centerDecorImage from "assets/services/asset-1.webp"
import leftDecorImage from "assets/services/asset-2.webp"
import rightDecorImage from "assets/services/asset-3.webp"

export const StyledHero = styled.section`
    position: relative;
    color: ${theme.colors.white};
    padding: 8rem 0 13rem;
    /* outline: 1px solid red; */
    
    ${mq(bp.small)} {
        padding: 9rem 0 22rem;
    }

    .decor {
        position: absolute;
        inset: 0;
        transform:  skewY(-12deg);
        z-index: -1;

        ${mq(bp.large)} {
            transform: skewY(-9deg);
        }

        .background-container,
        .stripes-container {
            ${flexColumn};
            justify-content: flex-end;
            align-items: center;
            position: absolute;
            ${size('100%')};
        }

        .grid,
        .grid-1 {
            display: grid;
            position: absolute;
            align-content: end;
            grid-template-rows: repeat(auto-fill, ${rem(64)});
            ${size('100%', '200%')};
        }
        
        .background-container {
            
            .grid {
                
                .background {
                    background-color: ${theme.colors.gray100};
                    grid-row: 1 / -2;
                    grid-column: 1 / -1;
                }
            }
        }
        
        .stripes-container {

            .grid-1 {
                height: 100%;
                grid-template-rows: repeat(auto-fill, ${rem(45)}); // tweak these values later
                grid-template-columns: repeat(auto-fill, minmax(${rem(30)}, 1fr));

                ${mq(bp.small)} {
                    grid-template-rows: repeat(auto-fill, ${rem(50)});
                    grid-template-columns: repeat(auto-fill, minmax(${rem(60)}, 1fr));
                }
                ${mq(bp.medium)} {
                    grid-template-rows: repeat(auto-fill, ${rem(64)});
                    grid-template-columns: repeat(auto-fill, minmax(${rem(80)}, 1fr));
                }
                ${mq(bp.large)} {
                    grid-template-columns: repeat(auto-fill, minmax(${rem(100)}, 1fr));
                }

                .stripe:nth-child(1) {
                    grid-column:  1 / span 1; // for positive values, span comes after '/' and vice versa
                    grid-row: span 1 / -2;
                    background-color: ${theme.colors.accent5};

                    ${mq(bp.xxlarge)} {
                        grid-column: 6 / span 3;
                    }
                }

                .stripe:nth-child(2) {
                    grid-column: 2 / span 2;
                    grid-row: span 1 /-3;
                    background-color: ${theme.colors.facebook};
                    
                    ${mq(bp.small)} {
                        grid-column: 2 / span 3;
                    }

                    ${mq(bp.xlarge)} {
                        grid-column: 2 / span 4;
                    }

                    ${mq(bp.xxlarge)} {
                        grid-column: 9 / span 2;
                    }
                }

                .stripe:nth-child(3) {
                    grid-column: span 1 / -1;
                    grid-row: span 1 / -3;
                    background-color: ${theme.colors.gray300};

                    ${mq(bp.xxlarge)} {
                        grid-column: span 1 / -8;
                    }
                }

                .stripe:nth-child(4) {
                    grid-column: span 3 / -2;
                    grid-row: span 1 / -2;
                    background-color:  ${alpha(theme.colors.accent400, .5)};

                    ${mq(bp.xlarge)} {
                        grid-column: span 4 / -2;
                    }

                    ${mq(bp.xxlarge)} {
                        grid-column: span 3 / -7;
                    }
                }
            }
        }
    }

    .decor-image-wrapper {
        position: absolute;
        border-radius: 10px;
        transform: skewY(-12deg);
        /* outline: 2px solid brown; */
        overflow: hidden;

        ${mq(bp.large)} {
            transform: skewY(-9deg);
        }

        .image {
            position: absolute;
            inset: -25%;
            transform: skewY(12deg);

            ${mq(bp.large)} {
                transform: skewY(9deg);
            }
        }
        
        &.center {
            top: 75%;
            left: 50%;
            transform: translateX(-50%) skewY(-12deg);
            ${size('90%', '50vw')};
            
            ${mq(bp.small)} {
                ${size(`min(90%, ${rem(600)})`, `min(50vw, ${rem(350)})`)};
                top: 65%;
            }
            
            ${mq(bp.medium)} {
                ${size(`min(90%, ${rem(550)})`, `min(50vw, ${rem(325)})`)};
            }
            
            ${mq(bp.large)} {
                transform: translateX(-50%) skewY(-9deg);
            }
            
            .image {
                background: url(${centerDecorImage}) center / cover no-repeat;
                /* outline: 2px solid red; */
            }
        }

        &.left {
            display: none;

            ${mq(bp.medium)} {
                display: block;
                left: -10%;
                top: 55%;
                ${size(`min(30%, ${rem(380)})`, `min(25%, ${rem(280)})`)};
                border-radius: 0 10px 10px 0;
                /* outline: 1px solid brown; */
            }

            ${mq(bp.large)} {
                left: -7%;
            }

            ${mq(bp.xlarge)} {
                top: 50%;
                left: 0;
                height: min(30%, ${rem(280)});
            }

            ${mq(bp.xxlarge)} {
                left: 20%;
            }
            
            .image {
                background: url(${leftDecorImage}) center / cover no-repeat;
            }
            
        }
        
        &.right {

            ${mq(bp.medium)} {
                display: block;
                right: -10%;
                top: 33%;
                ${size(`min(30%, ${rem(380)})`, `min(25%, ${rem(280)})`)};
                border-radius: 10px 0 0 10px;
                /* outline: 1px solid brown; */
            }

            ${mq(bp.large)} {
                right: -7%;
            }

            ${mq(bp.xlarge)} {
                top: 25%;
                right: 0;
                height: min(30%, ${rem(280)});
            }

            ${mq(bp.xxlarge)} {
                right: 20%;
            }
            
            .image {
                background: url(${rightDecorImage}) 20% 100% / cover no-repeat;

            }
        }
        
    }
    
    .text-content {
        text-align: center;
        padding-inline: 1rem;

        ${mq(bp.small)} {
            padding-inline: 0;
        }
        
        .heading {
            max-width: 20ch;
            margin-inline: auto;
            font-size: clamp(2rem, 5vw, 3rem);
            font-weight: 500;
            color: ${theme.colors.primary700};
            line-height: 1.2em;
            
            ${mq(bp.small)} {
                line-height: 1em;
            }
        }
        
        .summary {
            color: ${theme.colors.primaryDesat400};
            font-weight: 300;

            ${mq(bp.small)} {
                max-width: 50ch;
                font-size: ${rem(16)};
            }
        }
    }
`;