import styled from "styled-components";

import { rem, theme, size, mq, bp, alpha, pseudo, flexColumn } from "styles/Utilities.styled";
import HeroLarge from 'assets/hero-l.webp'

export const StyledHero = styled.section`
    position: relative;
    color: ${theme.colors.white};
    padding: 7rem 0;
    /* outline: 1px solid red; */
    
    ${mq(bp.small)} {
        padding: 9rem 0;
    }

    .decor {
        position: absolute;
        inset: 0;
        transform:  skewY(-12deg);
        z-index: -1;

        ${mq(bp.large)} {
            transform: skewY(-9deg);
        }

        .background-container,
        .stripes-container {
            ${flexColumn};
            justify-content: flex-end;
            align-items: center;
            position: absolute;
            ${size('100%')};
        }

        .grid {
            display: grid;
            position: absolute;
            align-content: end;
            grid-template-rows: repeat(auto-fill, ${rem(64)});
            ${size('100%', '200%')};
        }

        .background-container {

            .grid {
                grid-template-columns: 1fr;

                .background {
                    background-color: ${theme.colors.primary600};
                    grid-row: 1 / -2;
                    grid-column: 1 / -1;

                    ${mq(bp.small)} {
                        background: linear-gradient( 60deg, ${theme.colors.primary600} 35%, ${alpha(theme.colors.primary600, 0)}),
                            url(${HeroLarge});
                        background-size: cover;
                        background-position: 40% 90%;
                        /* background-position: 60% 100%; */
                        background-repeat: no-repeat;
                    }
                }
            }
        }

        .stripes-container {
            overflow: hidden;

            .grid {
                height: 100%;
                grid-template-rows: repeat(auto-fill, ${rem(45)}); // tweak these values later
                grid-template-columns: repeat(auto-fill, minmax(${rem(30)}, 1fr));

                ${mq(bp.small)} {
                    grid-template-rows: repeat(auto-fill, ${rem(50)});
                    grid-template-columns: repeat(auto-fill, minmax(${rem(60)}, 1fr));
                }
                ${mq(bp.medium)} {
                    grid-template-rows: repeat(auto-fill, ${rem(64)});
                    grid-template-columns: repeat(auto-fill, minmax(${rem(80)}, 1fr));
                }
                ${mq(bp.large)} {
                    grid-template-columns: repeat(auto-fill, minmax(${rem(100)}, 1fr));
                }

                .stripe:nth-child(1) {
                    grid-column:  1 / span 2; // for positive values, span comes after '/' and vice versa
                    grid-row: span 1 / -2;
                    background-color: ${theme.colors.accent400};
                }

                .stripe:nth-child(2) {
                    grid-column: 3 / span 1;
                    grid-row: span 1 /-2;
                    background-color: ${theme.colors.accent2};
                }

                .stripe:nth-child(3) {
                    grid-column: span 1 / -1;
                    grid-row: span 1 /-2;
                    background-color: ${theme.colors.gray300};
                }

                .stripe:nth-child(4) {
                    grid-column: 1 /span 1;
                    grid-row: span 1 /-5;
                    box-shadow: inset -0.5px 0 0 1.5px ${alpha(theme.colors.gray300, .2)};
                }

                .stripe:nth-child(5) {
                    grid-column: span 3 /-1;
                    grid-row: span 1 /-1;
                    background-color: ${theme.colors.accent3};
                }

                .stripe:nth-child(6) {
                    grid-column: span 2 /-1;
                    grid-row: span 1 / -3;
                    background-color: ${theme.colors.primary400};
                }
                
                .stripe:nth-child(7) {
                    grid-column: span 2 / -1;
                    grid-row: span 1 / -7;
                    box-shadow: inset 0.5px 0 0 1.5px ${alpha(theme.colors.accent3, .2)};
                }
            }
        }
    }
    
    .text-content {
        padding-left: 2rem;
        
        .greeting {
            position: relative;
            display: inline-block;
            margin-bottom: 1.5rem;
            color: ${theme.colors.gray300};
            font-weight: 300;
            font-size: ${rem(13)};
            
            &::before {
                ${pseudo};
                top: 50%;
                left: -15%;
                transform: translateY(-50%);
                width: ${rem(20)};
                height: 2px;
                background-color: ${theme.colors.accent400};
            }
        }
        
        h1 {
            font-family: ${theme.fonts.primary};
            font-size: clamp(2rem, 6vw, 4rem);
            line-height: 1.2em;

            ${mq(bp.small)} {
                line-height: 1em;
            }
        }

        p {
            margin-top: 1.5rem;
            color: ${theme.colors.gray100};
            font-size: ${rem(15)};
            font-weight: 300;

            ${mq(bp.small)} {
                max-width: 35ch;
                font-size: ${rem(16)};
            }
            ${mq(bp.medium)} {
                max-width: 45ch;
            }
        }

        .btn-container {
            ${flexColumn};
            align-items: start;
            gap: 1rem;
            margin-top: 1rem;

            ${mq('23.4375em')} { // For 375px and above
                flex-direction: row;
                align-items: center;
            }
        }
    }
`;
