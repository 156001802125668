import { useState } from "react";

import { Container } from "styles/components/Container.styled";
import { SectionHeading } from "styles/components/SectionText.styled";
import { StyledGallery } from "./Gallery.styled";
import { portfolio } from "data/portfolio";
import { Preloader } from "components/Preloader";

export const Gallery = () => {
  const [isLoading, setIsLoading] = useState(true);

  return (
    <StyledGallery>
      {isLoading && <Preloader />}
      <Container>
        <SectionHeading className="heading">
          Some of our Best Work yet
        </SectionHeading>
      </Container>

      <div className="gallery-grid">
        {portfolio.map(({ id, title, image, spanTwoCols, labels }) => {
          return (
            <div
              key={id}
              className={`img-wrapper ${spanTwoCols ? "span-2" : ""}`}
              title={title}
              tabIndex={0}>
              <img
                src={image}
                onLoad={() => id === 1 && setIsLoading(false)}
                loading={id > 3 ? "lazy" : "eager"}
                alt={title}
              />
              <div className="text-content">
                <h3 className="title">{title}</h3>
              </div>
            </div>
          );
        })}
      </div>
    </StyledGallery>
  );
};
