import { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { GlobalStyles } from "styles/GlobalStyles.styled";

// components
import { Header } from "components/Header";
import { Footer } from "components/Footer";
import { ScrollToTop } from "components/ScrollToTop";

// Pages
import { Home } from "pages/Home";
import { About } from "pages/About";
import { Error } from "pages/Error";
import { Work } from "pages/Work";
import { Services } from "pages/Services";
import { ContactUs } from "pages/ContactUs";
import { PrivacyPolicy } from "pages/PrivacyPolicy";
import { TermsOfService } from "pages/TermsOfService";

export function App() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <Router>
      <ScrollToTop />
      <GlobalStyles />
      <Header isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="about" element={<About />} />
        <Route path="work" element={<Work />} />
        <Route path="services" element={<Services />} />
        <Route path="contact-us" element={<ContactUs />} />
        <Route path="privacy-policy" element={<PrivacyPolicy />} />
        <Route path="terms-of-service" element={<TermsOfService />} />
        <Route path="*" element={<Error />} />
      </Routes>
      <Footer />
    </Router>
  );
}
