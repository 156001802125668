import { Hero } from "./sections/Hero";
import { History } from "./sections/History";
import { Partners } from "./sections/Partners";
import { Philosophy } from "./sections/Philosophy";
import { TechStack } from "./sections/TechStack";
import { TheTeam } from "./sections/TheTeam";


export const About = () => {
    return (
        <>
            <Hero />
            <History />
            <TheTeam />
            <Philosophy />
            <TechStack />
            <Partners />
        </>
    )
};
