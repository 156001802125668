import styled from "styled-components";
import { alpha, bp, flexColumn, mq, pseudo, rem, size, theme } from "styles/Utilities.styled";

export const StyledServices = styled.section`
    position: relative;
    padding: 7rem 0;
    margin-top: 10rem;
    background-color: ${theme.colors.gray100};

    ${mq(bp.small)} {
        margin-top: 11rem;
    }

    .decor-1,
    .decor-2 {
        position: absolute;
        width: min(25%, ${rem(300)});
        height: ${rem(40)};
        background-color: ${alpha(theme.colors.accent400, .5)};

        ${mq(bp.small)} {
            width: min(30%, ${rem(400)});
            height: ${rem(50)};
        }
        
        ${mq(bp.medium)} {
            height: ${rem(60)};
        }
        
        ${mq(bp.xxlarge)} {
            width: min(50%, ${rem(700)});
        }
        
        &::before {
            ${pseudo};
            width: 50%;
            height: 80%;
            background-color: ${alpha(theme.colors.accent2, .6)};
        }
    }

    .decor-1 {
        top: 0;
        right: 0;
        
        &::before {
            top: -45%;
            right: 0;
        }
        
    }
    
    .decor-2 {
        bottom: 0;
        left: 0;

        &::before {
            top: 65%;
            left: 0;
        }

    }

    .wrapper {
        ${mq(bp.medium)} {
            display: flex;
            flex-flow: row-reverse;
            gap: 3rem;

            & > div:first-child {
                flex-basis: 35%;
                margin-top: 1rem;
                /* outline: 1px solid red; */
            }
            & > div:last-child {
                flex-basis: 65%;
            }
        }

        ${mq(bp.large)} {
            gap: 5rem;
            & > div:first-child {
                flex-basis: 30%;
            }
            & > div:last-child {
                flex-basis: 70%;
                /* outline: 1px solid red; */
            }
        }
    }

    .sub-heading,
    .heading,
    .summary {
        ${mq(bp.medium)} {
            text-align: left;
        }
    }

    .services-cta {
        display: block;
        width: fit-content;
        margin: 1rem auto;

        ${mq(bp.medium)} {
            margin-left: 0;
        }
    }

    .cards-container {
        ${flexColumn};
        align-items: center;
        gap: 1.5rem;
        margin-top: 2rem;
        /* outline: 1px solid; */

        ${mq(bp.small)} {
            flex-flow: row wrap;
            justify-content: center;
            align-items: start;
        }

        ${mq(bp.medium)} {
            margin-top: 0;
        }
    }

    .card {
        position: relative;
        padding: 1.5rem 1.7rem;
        width: min(100%, ${rem(290)});
        min-height: ${rem(270)};
        background-color: ${alpha(theme.colors.white, .5)};
        text-align: center;
        border-radius: 10px;
        box-shadow: 0 0 10px ${alpha(theme.colors.primary700, .13)};
        transition: box-shadow .3s; // bad practice but mehh
        overflow: hidden;
        
        &:hover {
            box-shadow: 0 0 16px ${alpha(theme.colors.primary700, .1)},
            0 0 8px ${alpha(theme.colors.primary700, .15)};
        }
        
        ${mq(bp.medium)} {
            flex: 1 0 32.5%;
            min-height: ${rem(250)};
        }
        
        ${mq(bp.large)} {
            flex: 1 0 35%;
            text-align: left;
        }

        &::before {
            ${pseudo};
            top: -50%;
            left: 50%;
            transform: translate(-50%, 50%);
            ${size('50%')};
            border-radius: 50%;
            
            ${mq(bp.large)} {
                ${size('30%', '40%')};
                top: -10%;
                left: -5%;
                transform: none;
            }
        }
        
        &-icon {
            font-size: 2rem;
        }

        &-title {
            margin-top: 1rem;
            font-size: ${rem(18)};
            
            ${mq(bp.large)} {
                position: absolute;
                top: 12%;
                left: 28%;
                margin-top: 0.5rem;
            }
        }
        
        &-text {
            margin-top: 1rem;
            font-size: ${rem(15)};
            font-weight: 400;
            color: ${theme.colors.primaryDesat200};

            ${mq(bp.large)} {
                margin-top: 1.5rem;
            }
        }

        &-cta {
            margin-top: 1rem;
            font-size: ${rem(14)};

            ${mq(bp.large)} {
                padding-left: 0;
            }
        }
    }
    
    .card:nth-child(1) {
        &::before {
            background-color: ${alpha(theme.colors.accent400, .2)}
        }
    }
    .card:nth-child(2) {
        &::before {
            background-color: ${alpha(theme.colors.accent2, .2)}
        }
    }
    .card:nth-child(3) {
        &::before {
            background-color: ${alpha(theme.colors.accent3, .3)}
        }
    }
    .card:nth-child(4) {
        &::before {
            background-color: ${alpha(theme.colors.gray300, .3)}
        }
    }
`;