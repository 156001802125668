import { Container } from "styles/components/Container.styled";
import {
  SectionHeading,
  SectionSubHeading,
} from "styles/components/SectionText.styled";
import { StyledTechStack } from "./TechStack.styled";
import { AccentText } from "styles/components/AccentText.styled";
import { TechCard } from "components/TechCard";

// Logos
import FigmaLogo from "assets/tech stack/figma-color.svg";
import AdobeXDLogo from "assets/tech stack/adobexd-color.svg";
import AdobePSLogo from "assets/tech stack/adobephotoshop-color.svg";
import JSLogo from "assets/tech stack/javascript-color.svg";
import TSLogo from "assets/tech stack/typescript-color.svg";
import TailwindLogo from "assets/tech stack/tailwindcss-color.svg";
import SassLogo from "assets/tech stack/sass-color.svg";
import HTMLLogo from "assets/tech stack/html5-color.svg";
import CSSLogo from "assets/tech stack/css3-color.svg";
import ReactLogo from "assets/tech stack/react-color.svg";
import GoLogo from "assets/tech stack/go-color.svg";
import ReduxLogo from "assets/tech stack/redux-color.svg";
import MongoLogo from "assets/tech stack/mongodb-color.svg";
import RustLogo from "assets/tech stack/rust-color.svg";
import NodeLogo from "assets/tech stack/nodedotjs-color.svg";
import PythonLogo from "assets/tech stack/python-color.svg";
import MYSQLLogo from "assets/tech stack/mysql-color.svg";
import RedisLogo from "assets/tech stack/redis-color.svg";
import OracleLogo from "assets/tech stack/oracle-color.svg";
import FlutterLogo from "assets/tech stack/flutter-color.svg";
import PostgresLogo from "assets/tech stack/postgresql-color.svg";

export const TechStack = () => {
  return (
    <StyledTechStack>
      <Container>
        <SectionSubHeading>Our Tech Stack</SectionSubHeading>
        <SectionHeading>
          We use the latest <AccentText>technologies</AccentText>
        </SectionHeading>
        <div className="tech-stack-container">
          <TechCard text={"Figma"} logo={FigmaLogo} />
          <TechCard text={"AdobeXD"} logo={AdobeXDLogo} />
          <TechCard text={"Photoshop"} logo={AdobePSLogo} />
          <TechCard text={"HTML5"} logo={HTMLLogo} />
          <TechCard text={"CSS3"} logo={CSSLogo} />
          <TechCard text={"Tailwind"} logo={TailwindLogo} />
          <TechCard text={"Sass"} logo={SassLogo} />
          <TechCard text={"Javascript"} logo={JSLogo} />
          <TechCard text={"Typescript"} logo={TSLogo} />
          <TechCard text={"React"} logo={ReactLogo} />
          <TechCard text={"Golang"} logo={GoLogo} />
          <TechCard text={"Redux"} logo={ReduxLogo} className={"resize"} />
          <TechCard text={"MongoDB"} logo={MongoLogo} className={"resize"} />
          <TechCard text={"Rust"} logo={RustLogo} />
          <TechCard text={"NodeJS"} logo={NodeLogo} className={"resize"} />
          <TechCard text={"Python"} logo={PythonLogo} />
          <TechCard text={"MYSQL"} logo={MYSQLLogo} />
          <TechCard text={"Redis"} logo={RedisLogo} />
          <TechCard text={"Oracle"} logo={OracleLogo} />
          <TechCard text={"Flutter"} logo={FlutterLogo} />
          <TechCard text={"Postgres"} logo={PostgresLogo} />
        </div>
      </Container>
    </StyledTechStack>
  );
};
