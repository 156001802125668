import { FiPenTool } from "react-icons/fi";
import { BiCodeAlt, BiClipboard, BiBriefcase } from "react-icons/bi";
import { HiOutlineHome } from "react-icons/hi";
import { BsCloudArrowUp } from "react-icons/bs";

import { StyledServices } from "./Services.styled";
import { Container } from "styles/components/Container.styled";
import {
  SectionHeading,
  SectionSubHeading,
  SectionSummary,
} from "styles/components/SectionText.styled";
import { CircularCard } from "components/CircularCard";
import { alpha, theme } from "styles/Utilities.styled";

export const ServicesSection = () => {
  return (
    <StyledServices>
      <Container>
        <SectionSubHeading>Our Services</SectionSubHeading>
        <SectionHeading>How we can serve you</SectionHeading>
        <SectionSummary className="summary">
          As a dynamic, multi-skilled team, we can handle any digital challenge
          thrown our way, however, you'll get the most value from our team when
          we're responsible for the complete look, feel and functionality of
          your product.
        </SectionSummary>

        <div className="services-container">
          <CircularCard
            icon={FiPenTool}
            overrideLightColorAlpha={true}
            lightColor={alpha(theme.colors.accent400, 0.2)}
            deepColor={theme.colors.accent500}
            heading={"UI/UX Design"}
            cardText={
              "With over 50 digital projects and counting, we get what users want pretty easily. At Polymorph Labs, we take user research, competitive analysis, wireframes, user flows etc. very seriously."
            }
            bannerSubHeading={"Premium visuals, awesome experience"}
            textContentSubHeading={"We are all users"}
          />
          <CircularCard
            icon={BiCodeAlt}
            lightColor={theme.colors.accent2100}
            deepColor={alpha(theme.colors.accent2500, 0.9)}
            heading={"Software Development"}
            cardText={
              "Animated interfaces, payment gateway integration, bespoke CMS, consuming APIs from existing sources and all things relevant to mobile & web. We've mostly done it all."
            }
            bannerSubHeading={"Web? Mobile? We got you"}
            textContentSubHeading={"We are web and mobile friendly"}
          />
          <CircularCard
            icon={BiBriefcase}
            lightColor={theme.colors.accent3}
            deepColor={theme.colors.accent3500}
            heading={"Business Consulting"}
            cardText={
              "Got an idea? Let us help with a minimum viable product. Tap into our experience and innovation to create a quick prototype to test the waters."
            }
            bannerSubHeading={"For businesses any shape and size"}
            textContentSubHeading={"We can help validate your ideas"}
          />
          <CircularCard
            icon={HiOutlineHome}
            lightColor={theme.colors.accent4}
            deepColor={theme.colors.accent4500}
            heading={"Home automation"}
            cardText={
              "So you finally want to automate stuff at home. Too cool to walk to the switch? to close the door? Did I turn the AC off? - We are here for you!"
            }
            bannerSubHeading={"Augment your home with tech"}
            textContentSubHeading={"Tech at home"}
          />
          <CircularCard
            icon={BsCloudArrowUp}
            overrideLightColorAlpha={true}
            lightColor={alpha(theme.colors.primary400, 0.15)}
            deepColor={alpha(theme.colors.primary400)}
            heading={"IoT Systems"}
            cardText={
              "In a world where literally everything is connected, we can help you share data securely across multiple IoT devices and maximize its use."
            }
            bannerSubHeading={"Get everything connected"}
            textContentSubHeading={"Do more with real-time data"}
          />
          <CircularCard
            icon={BiClipboard}
            overrideLightColorAlpha={true}
            lightColor={alpha(theme.colors.gray300, 0.4)}
            deepColor={alpha(theme.colors.black, 0.85)}
            heading={"Training"}
            cardText={
              "Become a polymorph by learning with us. We have skilled professionals who are willing to share and give back. What are you waiting for?"
            }
            bannerSubHeading={"Come learn our secrets"}
            textContentSubHeading={"We love to share"}
          />
        </div>
      </Container>
    </StyledServices>
  );
};
