import { StyledPartners } from "./Partners.styled";
import { Container } from "styles/components/Container.styled";
import {
  SectionSubHeading,
  SectionHeading,
  SectionSummary,
} from "styles/components/SectionText.styled";
import { AccentText } from "styles/components/AccentText.styled";
import { partners } from "data/partners";

export const Partners = () => {
  return (
    <StyledPartners>
      <Container>
        <SectionSubHeading>Our Partners</SectionSubHeading>
        <SectionHeading>
          Some of our <AccentText>trusted</AccentText> partners
        </SectionHeading>
        <SectionSummary className="summary">
          Both big and small companies come to us for award-winning solutions.
          No scale of problems scare us. Challenges are always welcome.
        </SectionSummary>

        <ul className="partners-container">
          {partners.map(({ id, name, image }) => (
            <li className="img-wrapper" key={id}>
              <img src={image} alt={name} />
            </li>
          ))}
        </ul>
      </Container>
    </StyledPartners>
  );
};
