import styled from "styled-components";
import { bp, flexColumn, mq } from "styles/Utilities.styled";

export const StyledPhilosophy = styled.section`
    margin-top: 7rem;

    .cards-container {
        ${flexColumn};
        gap: 2rem;
        margin-top: 5rem;
        
        ${mq(bp.small)} {
            flex-flow: row wrap;
            justify-content: center;
            width: fit-content;
            margin: 3rem auto 0;
            /* outline: 1px solid; */
        }
    }
`;