import { useState } from "react";

import { Container } from "styles/components/Container.styled";
import { SectionSummary } from "styles/components/SectionText.styled";
import { StyledHero } from "./Hero.styled";
import { Preloader } from "components/Preloader";

export const Hero = () => {
  const [isLoading, setIsLoading] = useState(true);
  return (
    <StyledHero>
      {isLoading && <Preloader />}
      <div aria-hidden="true" className="decor">
        <div className="background-container">
          <div className="grid">
            <div className="background"></div>
          </div>
        </div>
        <div className="stripes-container">
          <div className="grid-1">
            <div className="stripe"></div>
            <div className="stripe"></div>
            <div className="stripe"></div>
            <div className="stripe"></div>
            <div className="stripe"></div>
            <div className="stripe"></div>
          </div>
        </div>
      </div>
      <div className="decor-image-wrapper left">
        <div className="image"></div>
        {/* using CSS to place image in div for flexibility*/}
        {isLoading && (
          <img
            aria-hidden="true"
            src={require("assets/contact-us/asset-1.webp")}
            onLoad={() => setIsLoading(false)}
            className="false-image"
            alt=""
          />
        )}
      </div>
      <div className="decor-image-wrapper right">
        <div className="image"></div>
      </div>
      <Container>
        <div className="text-content">
          {/* <h1 className="heading">Let's chart a course together</h1> */}
          {/* <h1 className="heading">We are the right catalyst to jumpstart your ideas</h1> */}
          <h1 className="heading">
            You have something on your mind? Let us know!
          </h1>
          <SectionSummary className="summary">
            Our research shows there's a simple two-step process to be the best
            on the market. The first is to have a good idea. The second is to{" "}
            <span className="highlight">contact us.</span>
          </SectionSummary>
        </div>
      </Container>
    </StyledHero>
  );
};
