import styled from 'styled-components'
import { bp, flexColumn, mq, rem, theme } from 'styles/Utilities.styled';

export const StyledHistory = styled.section`
    margin-top: 5rem;

    ${mq(bp.small)} {
        margin-top: 7rem;
    }
    ${mq(bp.medium)} {
        margin-top: 9rem;
    }
    ${mq(bp.large)} {
        margin-top: 11rem;
    }

    .wrapper {
        ${flexColumn};
        gap: 2rem;

        ${mq(bp.small)} {
            flex-direction: row;
        }
    }

    .text-content {
        position: relative;
        /* outline: 1px solid; */

        ${mq(bp.small)} {
            margin-top: 2rem;
        }
        
        ${mq(bp.medium)} {
            margin-top: 4rem;
        }

        .sub-heading,
        .heading {
            text-align: center;

            ${mq(bp.small)} {
                text-align: left;
            }
        }

        .summary {
            max-width: 50ch;
            
            ${mq(bp.small)} {
                position: absolute;
                top: 23%;
                left: 0;
                min-width: 125%;
                margin-left: 0;
                padding: 2em 2em 2em 0;
                text-align: left;
                background-color: ${theme.colors.white};
                border-radius: 8px;
                /* outline: 1px solid red; */
            }

            ${mq(bp.medium)} {
                top: 22%;
                padding: 3em 3em 3em 0;
            }

            ${mq(bp.large)} {
                top: 11%;
            }
        }
    }
    
    .img-wrapper {
        width: min(100%, ${rem(450)});
        border-radius: 8px;
        overflow: hidden;
        margin-inline: auto;

        .image-title {
            font-size: ${rem(14)};
            font-weight: 500;
        }
    }
`; 