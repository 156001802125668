// import { useId } from "react"; // attempting to use new hook from React 18

import { MdError } from "react-icons/md";

import { Container } from "styles/components/Container.styled";
import {
  SectionHeading,
  SectionSubHeading,
} from "styles/components/SectionText.styled";
import { StyledStrategy } from "./Strategy.styled";
import { ReactComponent as DiscoverIcon } from "assets/services/binoculars.svg";
import { ReactComponent as ResearchIcon } from "assets/services/research.svg";
import { ReactComponent as CodingIcon } from "assets/services/coding.svg";
import { ReactComponent as DeploymentIcon } from "assets/services/rocket.svg";
import { ReactComponent as DesignIcon } from "assets/services/design.svg";
import { ReactComponent as TestingIcon } from "assets/services/testing.svg";
import { strategy } from "data/strategy";

export const Strategy = () => {
  // const getID = useId(); // getting warnings where some elements have the same key

  return (
    <StyledStrategy>
      <Container>
        <SectionSubHeading>Our Battle Plan</SectionSubHeading>
        <SectionHeading>How we nail it</SectionHeading>

        <div className="steps-container">
          {strategy.map(({ counter, title, body }, index) => {
            return (
              <article className="step" tabIndex={0} key={index}>
                <span className="step-counter">{counter}</span>
                <div className="step-title-wrapper">
                  <h3 className="step-title">{title}</h3>
                  {counter === "01." ? (
                    <DiscoverIcon className="step-icon" title={title} />
                  ) : counter === "02." ? (
                    <ResearchIcon className="step-icon" title={title} />
                  ) : counter === "03." ? (
                    <DesignIcon className="step-icon" title={title} />
                  ) : counter === "04." ? (
                    <CodingIcon className="step-icon" title={title} />
                  ) : counter === "05." ? (
                    <TestingIcon className="step-icon" title={title} />
                  ) : counter === "06." ? (
                    <DeploymentIcon className="step-icon" title={title} />
                  ) : (
                    <MdError
                      color="red"
                      size={50}
                      title="Error getting right icon"
                    />
                  )}
                </div>
                <p className="step-body">{body}</p>
              </article>
            );
          })}
        </div>
      </Container>
    </StyledStrategy>
  );
};
