import { FindUs } from "./sections/FindUs";
import { Hero } from "./sections/Hero";

export const ContactUs = () => {
  return (
    <>
      <Hero />
      <FindUs />
    </>
  );
};
