import styled from "styled-components"
import { bp, flexColumn, mq, rem } from "styles/Utilities.styled";

export const StyledServices = styled.section`
    margin-top: 8rem;
    /* outline: 1px solid red; */

    ${mq(bp.medium)} {
        margin-top: 11rem;
    }

    .summary {
        ${mq(bp.small)} {
            max-width: 50ch;
            font-size: ${rem(16)};
        }
    }

    .services-container {
        ${flexColumn};
        gap: 3rem;
        margin-top: 5rem;
        
        ${mq(bp.small)} {
            gap: 2rem;
            flex-flow: row wrap;
            justify-content: center;
            width: fit-content;
            margin: 3rem auto 0;
            /* outline: 1px solid; */
        }
    }
`;