import { Link } from "react-router-dom";
import { RiArrowRightFill } from "react-icons/ri";

import { AccentText } from "styles/components/AccentText.styled";
import { Container } from "styles/components/Container.styled";
import {
  SectionHeading,
  SectionSubHeading,
  SectionSummary,
} from "styles/components/SectionText.styled";
import { StyledTheTeam } from "./TheTeam.styled";
import { teamMembers } from "data/team";

export const TheTeam = () => {
  return (
    <StyledTheTeam>
      <Container>
        <SectionSubHeading>Meet the squad</SectionSubHeading>
        <SectionHeading>
          A company is as good as its <AccentText>team</AccentText>
        </SectionHeading>
        <SectionSummary>
          With over 100 projects under our belt collectively and a team of
          expert software developers and designers - there is no digital product
          we can't create and no market we can't help you break.
        </SectionSummary>

        <div className="images-container">
          {teamMembers.map(({ id, img, name, role }) => {
            return (
              <div key={id} className="img-wrapper" tabIndex={0}>
                <img src={img} alt={name} />
                <div className="text-content">
                  <p className="name">{name}</p>
                  <p className="role">{role}</p>
                </div>
              </div>
            );
          })}
          <Link
            to="mailto:info@polymorphlabs.io"
            className="img-wrapper career">
            <p>Cool enough to join us?</p>
            <p>Shoot us a message!</p>
            <div className="cta">
              <RiArrowRightFill />
              <RiArrowRightFill />
            </div>
          </Link>
        </div>
      </Container>
    </StyledTheTeam>
  );
};
