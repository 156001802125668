import { Container } from "styles/components/Container.styled";
import { SectionSummary } from "styles/components/SectionText.styled";
import { StyledHero } from "./Hero.styled";

export const Hero = () => {
  return (
    <StyledHero>
      <div aria-hidden="true" className="decor">
        <div className="background-container">
          <div className="grid">
            <div className="background"></div>
          </div>
        </div>
        <div className="stripes-container">
          <div className="grid-1">
            <div className="stripe"></div>
            <div className="stripe"></div>
            <div className="stripe"></div>
            <div className="stripe"></div>
            <div className="stripe"></div>
            <div className="stripe"></div>
            <div className="stripe"></div>
            <div className="stripe"></div>
            <div className="stripe"></div>
          </div>
        </div>
      </div>
      <Container>
        <div className="text-content">
          <h1 className="heading">
            Our speech is good, but our delivery is even better
          </h1>
          <SectionSummary className="summary">
            We don't just talk fancy around here, no. We put our brains where
            our mouths are. Even our competition will attest to this if we had
            any! Scroll down for a fragment of our masterpieces.
          </SectionSummary>
        </div>
      </Container>
    </StyledHero>
  );
};
