import { Link } from "react-router-dom";
import { Container } from "styles/components/Container.styled";
import {
  SectionHeading,
  SectionSubHeading,
} from "styles/components/SectionText.styled";
import { StyledFindUs } from "./FindUs.styled";

export const FindUs = () => {
  return (
    <StyledFindUs>
      <Container>
        <div className="wrapper">
          <div className="text-content">
            <SectionSubHeading className="sub-heading">
              Get in touch
            </SectionSubHeading>
            <SectionHeading className="heading">Find Us</SectionHeading>
            <div className="info">
              <h4 className="small-heading">Say Hello:</h4>
              <p>+233 20 404 5782</p>
              <h4 className="small-heading">Leave a message:</h4>
              <Link to="mailto:info@polymorphlabs.io">
                info@polymorphlabs.io
              </Link>
              <h4 className="small-heading">Pay a visit:</h4>
              <p>No. 17 National Service Avenue</p>
              <p>Market Steet, Haatso Atomic Road</p>
              <p>09:00 AM - 05:00 PM GMT +0</p>
            </div>
          </div>
          <div className="iframe-wrapper">
            <iframe
              title="Nii Nortey Palm Cres, Accra"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d598.4225100694543!2d-0.19243608531210926!3d5.674614722146128!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xfdf9d36d8d8e63f%3A0xb3cecb3a70bd32d0!2sPolymorph%20Labs%20Ghana%20Limited!5e0!3m2!1sen!2sgh!4v1673213602165!5m2!1sen!2sgh"
              width=""
              height=""
              style={{ border: 0 }}
              loading="lazy"></iframe>
          </div>
        </div>
      </Container>
    </StyledFindUs>
  );
};
