import { Hero } from "./sections/Hero";
import { Strategy } from "./sections/Strategy";
import { ServicesSection } from "./sections/Services";

export const Services = () => {
  return (
    <>
      <Hero />
      <Strategy />
      <ServicesSection />
    </>
  );
};
