import {
  IoHappyOutline,
  IoTimeOutline,
  IoDesktopOutline,
} from "react-icons/io5";
import { BsCalendar2Check } from "react-icons/bs";

import { StyledStatistics } from "./Statistics.styled";
import { Container } from "styles/components/Container.styled";
import {
  SectionHeading,
  SectionSubHeading,
} from "styles/components/SectionText.styled";
import { AccentText } from "styles/components/AccentText.styled";

export const Statistics = () => {
  return (
    <StyledStatistics>
      <Container>
        <SectionSubHeading className="sub-heading">
          Our Statistics
        </SectionSubHeading>
        <SectionHeading className="heading">
          <AccentText>Polymorph Labs</AccentText> at a glance
        </SectionHeading>
        <div className="cards-container">
          <div className="card" tabIndex={0}>
            <IoHappyOutline className="card-icon" />
            <p className="card-amount">20+</p>
            <p className="card-text">Elated Clients</p>
          </div>
          <div className="card" tabIndex={0}>
            <BsCalendar2Check className="card-icon" />
            <p className="card-amount">30+</p>
            <p className="card-text">Successful Projects</p>
          </div>
          <div className="card" tabIndex={0}>
            <IoTimeOutline className="card-icon" />
            <p className="card-amount">4+</p>
            <p className="card-text">Years In Business</p>
          </div>
          <div className="card" tabIndex={0}>
            <IoDesktopOutline className="card-icon" />
            <p className="card-amount">20000+</p>
            <p className="card-text">Hours Of Work</p>
          </div>
        </div>
      </Container>
    </StyledStatistics>
  );
};
