import styled from "styled-components";
import { alpha, flexColumn, rem, size, theme } from "styles/Utilities.styled";

export const StyledTechCard = styled.div`
    ${flexColumn};
    justify-content: center;
    padding: .8rem .4rem .4rem;
    ${size(rem(110))};
    border: 1px solid ${alpha(theme.colors.primaryDesat200, .45)};
    border-radius: 5px;
    transition: box-shadow .4s, background-color .4s;

    &:hover,
    &:focus {
        background-color: ${alpha(theme.colors.white, .6)};
        box-shadow: 0px 1px 6px -1px rgba(0, 0, 0, .15);

        .img-wrapper {
            transform: scale(1) translateY(0);
        }

        .text {
            span {
                transform: translateY(-5px) skewY(0);
            }
        }
    }

    .img-wrapper {
        ${size(rem(64))};
        margin-inline: auto;
        transform: scale(1.2) translateY(15%);
        transition: transform .3s ${theme.ease.almostLinear};
        /* outline: 1px solid; */
        
        img {
            ${size('100%')};
        }
    }
    
    .text {
        margin-top: .5rem;
        text-align: center;
        font-size: ${rem(15)};
        color: ${theme.colors.primaryDesat300};
        transition: color .4s;
        overflow: hidden;
        /* outline: 1px solid red; */
        
        span {
            display: block;
            transform: translateY(120%) skewY(20deg);
            transition: transform .3s .1s ${theme.ease.almostLinear};
        }
    }
`;