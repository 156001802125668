import { FaQuoteLeft, FaQuoteRight } from "react-icons/fa";

import { StyledTestimonials } from "./Testimonials.styled";
import { Container } from "styles/components/Container.styled";
import {
  SectionHeading,
  SectionSubHeading,
} from "styles/components/SectionText.styled";
import { Carousel } from "components/Carousel";

export const Testimonials = () => {
  return (
    <StyledTestimonials>
      <Container>
        <FaQuoteLeft className="quote-left" />
        <FaQuoteRight className="quote-right" />
        <SectionSubHeading>Our Testimonials</SectionSubHeading>
        <SectionHeading>What clients are saying</SectionHeading>
        <Carousel />
      </Container>
    </StyledTestimonials>
  );
};
